import { Feature, Map as OlMap, View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Extent, getCenter } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Style, Text } from 'ol/style';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { MICROFENCE, MICROFENCE_LAYER_ID, POINT } from '../BeaconUtils';
import { MapContainer } from '../MapContainer/MapContainer';
import {
  createIndoorMapDefaults,
  createMap,
  createOutdoorMapDefaults,
  defaultPopUpStyle,
  defaultFenceEventLayer,
  defaultLabelLayer,
  defaultLinesLayer,
  defaultWalkerLayer,
  MapSourceType,
  olMicrofenceClusterLayer,
  olClusterLayer,
  olFenceLayers,
  selectedGeoOrMicroFenceStyle,
  getExtentDifference,
  updateMicrofenceStyle,
  defaultDropPinStyle,
} from '../FeatureStyles';
import {
  AssetState,
  DeviceLocation,
  FenceType,
  LocalBeacons,
  SensedAsset,
  SensedAssetsReport,
} from '../Messages';
import { ChangeMapSourceType } from '../Toolbar/LayerTools/ChangeMapSourceType';
import { MapToolbar } from '../Toolbar/MapToolbar';
import { ZoomIn } from '../Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../Toolbar/ZoomTools/ZoomOut';
import { Follow } from '../Toolbar/Follow';
import { updateLocationTrace } from '../../../hooks/geomoby/locationTrace';
import { fitToExtent, getCoordinateDifference, panTo } from '../../../hooks/geomoby/MapAnimation';
import { LiveStreamData } from '../../../hooks/geomoby/useGeomobyLiveStream';
import {
  PointsLayersMicrofences,
  StrongFeatureHolder,
} from '../../../hooks/geomoby/useLiveMapLoader';
import { LocalBeaconsPopover, useLocalBeacons } from '../../../hooks/geomoby/localBeacons';
import { useAtomValue, useSetAtom } from 'jotai';
import { CID, PID } from '../../../store/user';
import { Cluster } from 'ol/source';
import { transformExtent } from 'ol/proj';
import { debounce, truncate } from 'lodash';
import { LoadIndicator } from '../Toolbar/LayerTools/LoadIndicator';
import { click } from 'ol/events/condition';
import { Select } from 'ol/interaction';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import { GridRowData } from '@material-ui/data-grid';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import Popup from 'ol-ext/overlay/Popup';
import { MAP_API_KEYS, MAP_STATE } from '../../../store/map';
import { SensedAssetsPopover, useSensedAssets } from '../../../hooks/geomoby/sensedAssets';
import RenderFeature from 'ol/render/Feature';
import { TRIGGERS_URL } from '../../../store/url';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import axios from 'axios';
import { MultiPolygon } from 'ol/geom';
import { GeomobyOverride, Asset, PortableAssetTool, SelectedAsset } from '../types';
import { LocationDisplayType, LocationSearch, LocationSearchData } from '../Toolbar/LocationSearch';
import CircleStyle from 'ol/style/Circle';
import { KNOWN_TOOLS } from '../../../store/tools';
import {
  ASSET_ID,
  ASSET_LABEL,
  CLUSTER_MAX_ZOOM,
  FENCE_VIEWING_HEIGHT,
  initialExtentInDegrees,
  initialLatitude,
  initialLongitude,
  initialZoomHeight,
  LOCAL_BEACONS,
  SENSED_ASSETS,
  SENSED_EXITED_ASSETS_IDS,
  ZOOM_THRESHOLD,
} from '../../../util/constants';
import { deselectFences } from '../Helpers';
import { AssetEntity, MapType } from '../../../util/enums';
import {
  doMapUpdates,
  doPinUpdate,
  unlocaliseAssetId,
  updateAssetSelection,
  updateMicrofencesActivity,
} from './MapUpdates';
import { MapState } from './Props';

/* Live/Replay Map */
export function MapRenderer({
  selectedAsset,
  setSelectedAsset,
  streamedData,
  userExtent,
  setUserExtent,
  selectedGeofence,
  setSelectedGeofence,
  selectedMicrofence,
  setSelectedMicrofence,
  setSelectedBeacon,
  setSelectedDevice,
  setSelectedGPSTracker,
  setSelectedTool,
  liveMapStaticData,
  resetStyling,
  mapType,
  resetStateRef,
  showLocationTraces,
  onExtentChanged,
  fencesLoading,
  locationSearchData,
  setCurrentCenter,
  locationDisplay,
  navigateTo,
  setNavigateTo,
  selectedFromMap,
  setSelectedFromMap,
  deselectFence,
  setDeselectFence,
  setLocationDisplay,
  setLocationSearchData,
  getNow,
}: {
  selectedAsset: SelectedAsset | undefined;
  setSelectedAsset: Dispatch<SetStateAction<SelectedAsset | undefined>>;
  streamedData: LiveStreamData;
  userExtent: Extent | undefined;
  setUserExtent: Dispatch<SetStateAction<Extent | undefined>>;
  selectedGeofence: GridRowData | undefined;
  setSelectedGeofence: Dispatch<GridRowData | undefined>;
  selectedMicrofence: GridRowData | undefined;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setSelectedBeacon: Dispatch<Asset | undefined>;
  setSelectedDevice: Dispatch<Asset | undefined>;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  liveMapStaticData: PointsLayersMicrofences | undefined;
  resetStyling: boolean;
  mapType: MapType;
  resetStateRef?: { current: () => void };
  showLocationTraces?: boolean;
  onExtentChanged: Dispatch<
    SetStateAction<{ latitude: number; longitude: number; extentInDegrees: number }>
  >;
  fencesLoading: boolean;
  locationSearchData: LocationSearchData | undefined;
  setCurrentCenter: Dispatch<SetStateAction<number[] | undefined>>;
  locationDisplay: LocationDisplayType | undefined;
  navigateTo: string | null;
  setNavigateTo: Dispatch<SetStateAction<string | null>>;
  selectedFromMap: boolean;
  setSelectedFromMap: Dispatch<SetStateAction<boolean>>;
  deselectFence: boolean;
  setDeselectFence: Dispatch<SetStateAction<boolean>>;
  setLocationDisplay: Dispatch<LocationDisplayType | undefined>;
  setLocationSearchData: Dispatch<LocationSearchData | undefined>;
  getNow: () => Date;
}) {
  const [dropLocationPin, setDropLocationPin] = useState<Feature<Point> | undefined>();
  const [followingAsset, setFollowingAsset] = useState<boolean>(false);
  const [localBeacons, setLocalBeacons] = useLocalBeacons();
  const [mapSource, setMapSource] = useState<MapSourceType>('Terrain & Roads');
  const [mapState, setMapState] = useState<MapState | undefined>();
  const [selectClick, setSelectClick] = useState<Select | undefined>(undefined);
  const [sensedAssets, setSensedAssets] = useSensedAssets();
  const [specifiedCoordinates, setSpecifiedCoordinates] = useState<[number, number]>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersBaseUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const mapApiKeys = useAtomValue(MAP_API_KEYS);
  const knownTools = useAtomValue(KNOWN_TOOLS);

  const assetSelectedRef = useRef<boolean>(false);
  const currentlyDisplayedTripwiresRef = useRef<Feature<Geometry>[]>([]);
  const debouncedOnMapMoved = useRef(
    debounce(
      (olmap: OlMap) => {
        changeBounds(olmap);
      },
      500,
      { leading: true },
    ),
  ).current;
  const extentInDegreesRef = useRef<number>(initialExtentInDegrees);
  const isRecenteringRef = useRef<boolean>(false);
  const layersRef =
    useRef<
      { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> }[] | undefined
    >();
  const locationDisplayRef = useRef<LocationDisplayType | undefined>(locationDisplay);
  const microfenceLayer = useRef<AnimatedCluster>();
  const numberOfFencesInClusterRef = useRef<number>(0);
  const popUpHoverRef = useRef<Popup>(new Popup());
  const popUpNameRef = useRef<string>('');
  const selectedFenceRef = useRef<GeoJSONFeature>();
  const selectedFromPixelsRef = useRef<boolean>(false);
  const hideClustersRef = useRef<boolean>(false);
  const storeMapState = useSetAtom(MAP_STATE);
  const styleCache = new Map<string, Style[]>();

  const { state: liveState, lastUpdates } = streamedData;

  if (!specifiedCoordinates) {
    navigator.geolocation?.getCurrentPosition(
      loc => {
        setSpecifiedCoordinates([loc?.coords.longitude, loc?.coords.latitude]);
      },
      () => {
        setSpecifiedCoordinates([initialLongitude, initialLatitude]);
      },
    );
  }

  const animateToSearchedLocation = useCallback(
    async (view: View, coords: number[], isStreetAddress?: boolean): Promise<void> => {
      return new Promise((resolve, reject) => {
        const duration = Math.min(
          6000,
          Math.max(
            300,
            getCoordinateDifference(view.getCenter() ?? [0, 0], coords ?? [0, 0]) / 1000,
          ),
        );

        view.animate(
          {
            center: coords,
            zoom: duration > 300 ? 9 - duration / 1000 : view.getZoom(),
            duration: duration,
          },
          () => {
            view.animate(
              {
                resolution: isStreetAddress ? 18 : 14,
                duration: 2000,
              },
              () => {
                resolve();
              },
            );
          },
        );
      });
    },
    [],
  );

  const getGeofence = useCallback(
    async (fenceId: string, layerId: string, fenceType: FenceType) => {
      return (
        await axios.get<GridRowData>(
          `${triggersBaseUrl}/${cid}/${pid}/geofences/${layerId}/${fenceType}/${fenceId}`,
          authedConfig,
        )
      ).data;
    },
    [triggersBaseUrl, cid, pid, authedConfig],
  );

  const findFeature = useCallback(
    async (olmap: OlMap, layerId: string, fenceId: string, type: FenceType) => {
      if (fenceId) {
        const layer = olmap.getAllLayers().find(l => l.getSource().get('id') === layerId);
        if (layer && (layer instanceof VectorLayer || layer instanceof AnimatedCluster)) {
          const fences: Feature<Geometry>[] = layer.getSource().getFeatures();
          if (!fences.find(f => f.get('id') === fenceId)) {
            getGeofence(fenceId, layerId, type).then(updatedFence => {
              if (updatedFence) {
                setSelectedGeofence({
                  ...updatedFence,
                  layerId,
                });
              }
            });
          }
        }
      }
    },
    [getGeofence, setSelectedGeofence],
  );

  const animateToFeature = useCallback(
    async ([lon1, lat1, lon2, lat2]: Extent, olmap: OlMap) => {
      const duration = Math.min(
        6000,
        Math.max(
          300,
          getCoordinateDifference(
            olmap.getView().getCenter() ?? [0, 0],
            getCenter([lon1, lat1, lon2, lat2]) ?? [0, 0],
          ) / 1000,
        ),
      );
      olmap.getView().animate(
        {
          center: getCenter([lon1, lat1, lon2, lat2]),
          zoom: duration > 300 ? 9 - duration / 1000 : olmap.getView().getZoom(),
          duration: duration,
        },
        () => {
          const zoom = olmap.getView().getZoom();
          if (zoom && zoom > FENCE_VIEWING_HEIGHT) {
            fitToExtent(olmap.getView(), [lon1, lat1, lon2, lat2]);
            if (selectedGeofence && selectedGeofence.points) {
              const type =
                selectedGeofence.points.type === 'LineString'
                  ? 'line'
                  : selectedGeofence.points?.type?.toLowerCase();
              findFeature(olmap, selectedGeofence?.layerId, selectedGeofence?.id, type);
            }
            debouncedOnMapMoved(olmap);
            return;
          }

          olmap.getView().animate(
            {
              center: getCenter([lon1, lat1, lon2, lat2]),
              duration: 250,
              resolution: olmap
                .getView()
                .getResolutionForZoom(
                  zoom === FENCE_VIEWING_HEIGHT ? FENCE_VIEWING_HEIGHT + 0.1 : FENCE_VIEWING_HEIGHT,
                ),
            },
            () => {
              if (
                selectedGeofence?.layerId !== MICROFENCE_LAYER_ID &&
                numberOfFencesInClusterRef.current <= 1
              ) {
                if (selectedGeofence && selectedGeofence.points) {
                  const type =
                    selectedGeofence.points.type === 'LineString'
                      ? 'line'
                      : selectedGeofence.points?.type?.toLowerCase();
                  findFeature(olmap, selectedGeofence?.layerId, selectedGeofence?.id, type);
                }
                debouncedOnMapMoved(olmap);
                fitToExtent(olmap.getView(), [lon1, lat1, lon2, lat2]);
              }
            },
          );
        },
      );
    },
    [debouncedOnMapMoved, findFeature, selectedGeofence],
  );

  useEffect(() => {
    return () => {
      mapState?.map?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    hideClustersRef.current = !!selectedGeofence || !!selectedMicrofence;
  }, [selectedGeofence, selectedMicrofence]);

  useEffect(() => {
    storeMapState(mapState);
  }, [storeMapState, mapState]);

  useEffect(() => {
    if (!liveMapStaticData || !mapType || mapState || !specifiedCoordinates) return;
    const { map: olmap, setSource: setMapSource } = createMap(
      mapType,
      mapType.includes('OUTDOOR')
        ? createOutdoorMapDefaults({
            sourceType: mapSource,
            edit: mapType.includes('EDIT'),
            specifiedCoordinates: specifiedCoordinates ?? [initialLongitude, initialLatitude],
            mapApiKeys,
          })
        : createIndoorMapDefaults(),
    );

    const { src: lineSrc, lyr: lineLyr } = defaultLinesLayer();
    olmap.addLayer(lineLyr);

    const { src: labelSrc, lyr: labelLyr } = defaultLabelLayer();
    olmap.addLayer(labelLyr);

    layersRef.current = olFenceLayers(liveMapStaticData.layers);

    microfenceLayer.current = olMicrofenceClusterLayer(liveMapStaticData.microfences, styleCache, {
      showActivity: true,
    });
    olmap.addLayer(microfenceLayer.current);

    const pointLayer = olClusterLayer(liveMapStaticData.points);
    olmap.addLayer(pointLayer);

    const { src: wSrc, lyr: wLyr } = defaultWalkerLayer();
    olmap.addLayer(wLyr);

    const { src: feSrc, lyr: feLyr } = defaultFenceEventLayer();
    olmap.addLayer(feLyr);

    setMapClickSelection(olmap, {
      excludeLayers: [lineLyr, labelLyr, wLyr, feLyr],
    });

    olmap.on('click', evt => {
      deselectFences(olmap);
      let clusterSelected = false;
      popUpHoverRef.current.hide();
      selectedFenceRef.current = undefined;
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      setSelectedBeacon(undefined);
      setSelectedDevice(undefined);
      setSelectedGPSTracker(undefined);
      setSelectedTool(undefined);
      setLocationDisplay(undefined);
      setLocationSearchData(undefined);
      setSelectedAsset(undefined);
      const selectedFences: Feature<Geometry>[] = [];

      olmap.getAllLayers().forEach(layer => {
        if (layer instanceof AnimatedCluster && layer.get('id') === MICROFENCE_LAYER_ID) {
          layer
            .getSource()
            .getFeatures()
            .forEach(feature => {
              feature?.get('features').filter((f: Feature<Geometry>) => {
                if (f.get('selected')) {
                  f.set('selected', false);
                }
              });
            });
        }
      });

      const walkerFeature = olmap.forEachFeatureAtPixel(evt.pixel, (f, l) => {
        if (l === wLyr) {
          return f;
        }
        if (l.get('id') === POINT) {
          clusterSelected = true;
        }
        if (
          f.get('points')?.type === 'Polygon' ||
          f.get('points')?.type === 'MultiPolygon' ||
          f.get('points')?.type === 'LineString'
        ) {
          selectedFences.push(f as Feature<Geometry>);
        }
      });

      if (selectedFences.length > 0 && !(walkerFeature || clusterSelected)) {
        let smallestExtentArea = Number.MAX_SAFE_INTEGER;
        let selectedFence: Feature<Geometry> = selectedFences[0];
        selectedFences.forEach(f => {
          const extent = f.getGeometry()?.getExtent();
          const extentArea = extent
            ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
            : Number.MAX_SAFE_INTEGER;
          if (smallestExtentArea > extentArea) {
            smallestExtentArea = extentArea;
            selectedFence = f;
          }
        });
        selectedFromPixelsRef.current = true;
        setSelectedFeature(olmap, selectedFence);
        updateClusters(olmap, pointLayer);
        return;
      }

      if (
        (selectedMicrofence ||
          (selectedMicrofence &&
            (selectedMicrofence as GridRowData).id !== selectedFenceRef.current?.get('id'))) &&
        selectedFenceRef.current?.get('layerId') === MICROFENCE_LAYER_ID
      ) {
        selectedFenceRef.current.set('selected', false);
      }

      if (!walkerFeature || clusterSelected) {
        if (!clusterSelected) {
          setSelectedGeofence(undefined);
          setSelectedMicrofence(undefined);
        }
        if (!walkerFeature) {
          lineSrc.clear();
          labelSrc.clear();
        }
        numberOfFencesInClusterRef.current = 0;
        updateClusters(olmap, pointLayer);
        setFollowingAsset(false);
        setSelectedAsset(undefined);
        return;
      }
      const id = walkerFeature?.get(ASSET_ID);
      const label = walkerFeature?.get(ASSET_LABEL);
      if (!id || !label) {
        console.warn('Should not be able to select a walker without id or label', walkerFeature);
        return;
      }
      const asset: SelectedAsset = { id, label, following: true };
      setFollowingAsset(true);
      setSelectedAsset(asset);
      assetSelectedRef.current = true;

      if (asset.id?.deviceId) {
        setSelectedDevice({
          id: asset.id?.deviceId as string,
          label: asset.label ?? '',
          type: AssetEntity.Device,
        });
      } else if (asset.id?.gpsTrackerId) {
        setSelectedGPSTracker({
          id: asset.id?.gpsTrackerId as string,
          label: asset.label ?? '',
          type: AssetEntity.GPSTracker,
        });
      } else if (asset.id?.beaconId) {
        const foundTool = knownTools.find(t => t.id === asset.id?.beaconId);
        if (foundTool) {
          setSelectedTool(foundTool as PortableAssetTool);
        } else {
          setSelectedBeacon({
            id: asset.id.beaconId as string,
            label: asset.label ?? '',
            type: AssetEntity.Beacon,
          });
        }
      }
    });

    olmap.on('moveend', () => {
      const zoom = olmap.getView().getZoom() || 0;
      setCurrentCenter(olmap.getView().getCenter());
      debouncedOnMapMoved(olmap);
      popUpHoverRef.current.hide();
    });

    olmap.getView().on('change:resolution', () => {
      updateClusters(olmap, pointLayer);
    });

    // Remove close button
    popUpHoverRef.current?.getElement()?.childNodes[0].remove();
    olmap.addOverlay(popUpHoverRef.current);

    olmap.on('pointermove', e => {
      let mousedOver: RenderFeature | Feature<Geometry> | undefined;
      const nonFences = olmap
        .getFeaturesAtPixel(e.pixel)
        .filter(nf => nf.get('ASSET_LABEL') || nf.get('event') || nf.get('searchedCoordinates'));
      let foundFeature = nonFences.length > 0;

      if (nonFences.length === 0) {
        const geoFences = olmap
          .getFeaturesAtPixel(e.pixel)
          .filter(
            f =>
              (f.get('id') && f.get('layerId')) ||
              (f.get('features')?.length > 0 &&
                f.get('features')[0].get('id') &&
                f.get('features')[0].get('layerId')),
          );
        if (geoFences.length > 0) {
          let smallestExtentArea = Number.MAX_SAFE_INTEGER;
          mousedOver = geoFences[0];
          geoFences.forEach(f => {
            const extent = f.getGeometry()?.getExtent();
            const extentArea = extent
              ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
              : Number.MAX_SAFE_INTEGER;
            if (extent && smallestExtentArea > extentArea) {
              smallestExtentArea = extentArea;
              mousedOver = f;
              foundFeature = true;
            }
          });
        }
      }

      if (!foundFeature) {
        popUpHoverRef.current.hide();
        popUpNameRef.current = '';
        return;
      }
      const fences: GeoJSONFeature[] = mousedOver?.get('features') ?? mousedOver ?? [];
      const fence: GeoJSONFeature = Array.isArray(fences) && fences.length > 0 ? fences[0] : fences;

      if (
        Array.isArray(fences) &&
        fences.length > 1 &&
        fences[0].get('type') === POINT &&
        !fenceSharesCommonPoint(fences)
      )
        return;

      const zoom = olmap.getView().getZoom() || 0;
      const coordinate = [e.coordinate[0], e.coordinate[1] - (olmap.getView().getMaxZoom() - zoom)];
      createPopupLabel(
        nonFences.length > 0
          ? nonFences
          : !Array.isArray(fences) || fences.length === 1
          ? [fence]
          : fences,
        coordinate,
      );
    });

    setMapState({
      map: olmap,
      layers: layersRef.current,
      microfenceLayer: microfenceLayer.current,
      microfences: liveMapStaticData.microfences,
      assets: undefined,
      assetSrc: wSrc,
      assetLyr: wLyr,
      fenceEvents: undefined,
      feSrc,
      feLyr,
      lineSrc,
      labelSrc,
      selectedAsset: undefined,
      setMapSource: setMapSource,
      setLocalBeacons,
      setSensedAssets,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMapStaticData, setLocalBeacons, setSensedAssets, specifiedCoordinates]);

  useEffect(() => {
    if (!liveMapStaticData || !mapType || !mapState) return;

    const olmap = mapState.map;
    layersRef.current = olFenceLayers(liveMapStaticData.layers);
    microfenceLayer.current = olMicrofenceClusterLayer(liveMapStaticData.microfences, styleCache, {
      showActivity: true,
    });
    const zoom = olmap.getView().getZoom() || 0;
    const useClusters = zoom < CLUSTER_MAX_ZOOM;
    const newLayers: VectorLayer<VectorSource<Geometry>>[] = [];

    olmap.getAllLayers().forEach(layer => {
      if (!(layer instanceof VectorLayer)) {
        return;
      }
      const id = layer.getProperties().source.get('id');
      if (!id) return;

      const newLayer = layersRef.current?.find(lyr => lyr.id === id);
      if (!newLayer) return;
      olmap.removeLayer(layer);
      if (selectedGeofence) {
        selectedFenceRef.current = newLayer.source
          .getProperties()
          .source.getFeatures()
          .find((f: Feature<Geometry>) => f.get('id') === selectedGeofence.id);
        if (selectedFenceRef.current) {
          selectedFenceRef.current.set('selected', true);
        }
      }
      newLayers.push(newLayer.source);
    });

    setNumberOfArrowsOnTripwires(olmap);
    newLayers.forEach(layer => {
      olmap.addLayer(layer);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMapStaticData]);

  useEffect(() => {
    if (!lastUpdates || !mapState) return;
    setTimeout(() => {
      if (mapState) {
        doMapUpdates(mapState, lastUpdates, cid, pid);
      }
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdates]);

  useEffect(() => {
    if (!mapState) return;
    const interval = setInterval(() => updateMicrofencesActivity(mapState, getNow()));
    return () => clearInterval(interval);
  }, [mapState, getNow]);

  useEffect(() => {
    if (!mapState) return;

    const featureForAsset =
      selectedAsset && selectedAsset.label
        ? mapState.assets?.find(asset => asset.id === selectedAsset.label)
        : null;

    const microfenceFeature = mapState.microfences.find(
      m => JSON.stringify(m.data.assetId) === JSON.stringify(selectedAsset?.id),
    );

    selectClick?.getFeatures().clear();
    if (microfenceFeature) {
      selectClick?.getFeatures().push(microfenceFeature.feature);
      const mfSource = mapState?.microfenceLayer.getSource();
      if (mfSource instanceof Cluster) {
        const clusterFeature = mfSource
          .getFeatures()
          .find(f =>
            (f.get('features') as Feature<Point>[])?.find(ff => ff === microfenceFeature.feature),
          );
        if (clusterFeature) {
          selectClick?.getFeatures().push(clusterFeature);
        }
      }

      setSensedAssets(
        microfenceFeature.feature?.get(SENSED_ASSETS) ?? undefined,
        transform(microfenceFeature.data.point.coordinates, 'EPSG:4326', 'EPSG:3857'),
      );
    } else {
      setSensedAssets(undefined, [0, 0]);
    }

    updateAssetSelection(mapState, selectedAsset);
    setLocalBeacons(
      featureForAsset?.point.get(LOCAL_BEACONS) ?? undefined,
      featureForAsset?.point.getGeometry()?.getCoordinates() ?? [0, 0],
    );
    if (showLocationTraces && featureForAsset) {
      updateLocationTrace(mapState, featureForAsset.point);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, showLocationTraces, lastUpdates]);

  useEffect(() => {
    if (!resetStyling) return;
    if (!mapState) return;
    mapState.assetSrc.getFeatures().forEach(f => {
      f.unset('wfCheck');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetStyling]);

  // Animate to selected asset
  useEffect(() => {
    if (!mapState || !selectedAsset) {
      return;
    }

    const selectedLabel = selectedAsset.label;

    const asset = !selectedLabel
      ? undefined
      : liveState.assets.find(asset => asset.assetId === selectedLabel) ||
        liveState.assets
          .filter(asset => asset.assetState.source?.label === unlocaliseAssetId(selectedLabel))
          .sort(
            (a, b) =>
              new Date(b.assetState.lastLocation?.timestamp || 0).getTime() -
              new Date(a.assetState.lastLocation?.timestamp || 0).getTime(),
          )
          .at(0);

    const lastLocation =
      asset?.assetState.lastLocation ?? asset?.assetState.lastLocalBeacons?.deviceLocation;
    if (!lastLocation) {
      // Microfences don't receive live location updates, they just have a point in their data
      const microfence = mapState.microfences.find(
        m => JSON.stringify(m.data.assetId) === JSON.stringify(selectedAsset.id),
      );
      if (microfence) {
        panTo(
          mapState.map.getView(),
          fromLonLat(microfence.data.point.coordinates),
          (b: boolean) => {},
        );
        return;
      }
      return;
    }
    const ll = lastLocation;

    //temp no pan in indoor maps
    panTo(mapState.map.getView(), fromLonLat([ll.lon, ll.lat]), (b: boolean) => {});
    assetSelectedRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset]);

  useEffect(() => {
    if (!!mapState && mapState.setMapSource) mapState.setMapSource(mapSource);
  }, [mapState, mapSource]);

  useEffect(() => {
    locationDisplayRef.current = locationDisplay;
  }),
    [locationDisplay];

  useEffect(() => {
    if (!mapState) return;
    const olmap = mapState.map;
    if (!dropLocationPin) {
      olmap?.setLayers(
        olmap?.getAllLayers().filter(layer => layer?.getClassName() !== 'pin-layer'),
      );
    } else {
      const pinLayer = olmap?.getAllLayers().find(layer => layer?.getClassName() === 'pin-layer');
      if (pinLayer) return;
      olmap?.addLayer(
        new VectorLayer({
          className: 'pin-layer',
          source: new VectorSource({
            features: [dropLocationPin],
          }),
          style: defaultDropPinStyle,
        }),
      );
    }
  }, [dropLocationPin, mapState]);

  useEffect(() => {
    if (!mapState || isRecenteringRef.current) return;
    if (!locationSearchData) {
      setDropLocationPin(undefined);
      return;
    }

    const { coords, address, isStreetAddress, isLastKnownLocation } = locationSearchData;
    const olmap = mapState.map;
    isRecenteringRef.current = true;
    animateToSearchedLocation(olmap.getView(), coords, isStreetAddress).then(() => {
      const pin = doPinUpdate(
        coords,
        address !== undefined ? { address } : { isLastKnownLocation: !!isLastKnownLocation },
      );
      setDropLocationPin(pin);
      isRecenteringRef.current = false;
    });
  }, [locationSearchData, mapState, animateToSearchedLocation]);

  useEffect(() => {
    if (!navigateTo || !mapState || !userExtent || !mapState?.map) return;
    if (navigateTo === (selectedGeofence?.id ?? selectedMicrofence?.id)) {
      animateToFeature(userExtent, mapState?.map);
      setNavigateTo(null);
    }
  }, [
    selectedGeofence,
    selectedMicrofence,
    navigateTo,
    animateToFeature,
    mapState?.map,
    mapState,
    setNavigateTo,
    userExtent,
  ]);

  useEffect(() => {
    if (
      selectedFromMap ||
      !layersRef.current ||
      !(selectedGeofence ?? selectedMicrofence)?.selected ||
      !mapState
    )
      return;

    deselectFences(mapState.map);
    let fenceVisibleOnScreen;
    if (selectedGeofence) {
      layersRef.current?.map(layer => {
        if (layer.id === (selectedGeofence ?? selectedMicrofence)?.layerId) {
          fenceVisibleOnScreen = layer.source
            .getSource()
            .getFeatures()
            .find(f => f.get('id') === (selectedGeofence ?? selectedMicrofence)?.id);
          if (fenceVisibleOnScreen) {
            fenceVisibleOnScreen.set('selected', true);
            selectedFenceRef.current = fenceVisibleOnScreen;
          }
        }
      });
    } else if (selectedMicrofence) {
      fenceVisibleOnScreen = mapState.map
        .getAllLayers()
        .find(layer => layer instanceof AnimatedCluster && layer.get('id') === MICROFENCE_LAYER_ID)
        ?.getSource()
        .getFeatures()
        .map((fence: Feature<Geometry>) => fence.getProperties()?.features?.[0])
        .find((fence: Feature<Geometry>) => fence.get('id') === selectedMicrofence?.id);
      fenceVisibleOnScreen?.set('selected', true);
      selectedFenceRef.current = fenceVisibleOnScreen;
    }

    mapState.map.getView().adjustZoom(-1);
    mapState.map.getView().adjustZoom(+1);
  }, [selectedFromMap, selectedGeofence, selectedMicrofence, mapState]);

  useEffect(() => {
    if (!deselectFence || !mapState) return;
    deselectFences(mapState.map);
    setDeselectFence(false);
    mapState.map.getView().adjustZoom(-1);
    mapState.map.getView().adjustZoom(+1);
  }),
    [deselectFence, mapState];

  const changeBounds = (olmap: OlMap) => {
    const view = olmap?.getView();
    if (!view) return;
    const viewCenter = view.getCenter();
    if (!viewCenter) return;
    const center = transform(viewCenter, view.getProjection(), 'EPSG:4326');
    onExtentChanged({
      latitude: center[1],
      longitude: center[0],
      extentInDegrees: extentInDegreesRef.current ?? initialExtentInDegrees,
    });
  };

  const setNumberOfArrowsOnTripwires = (olmap: OlMap) => {
    const res = olmap.getView().getResolution() || 0;
    olmap.getAllLayers().filter(layer => {
      if (layer instanceof VectorLayer) {
        const source: Cluster = layer.getProperties().source;
        const features = source.getFeatures().forEach(f => {
          const filteredFeatures: Feature<Geometry>[] = (f.getProperties().features ?? []).filter(
            (feat: Feature<Geometry>) => feat.get('points')?.type === 'LineString',
          );
          currentlyDisplayedTripwiresRef.current = Array.from(
            new Set([...currentlyDisplayedTripwiresRef.current, ...filteredFeatures]),
          );
        });
      }
    });
    currentlyDisplayedTripwiresRef.current.forEach(f => {
      const extent = f.getGeometry()?.getExtent();
      if (extent && extent.length > 0) {
        const size = Math.abs(extent[0] - extent[2]) + Math.abs(extent[1] - extent[3]);
        f.setProperties({ numberOfArrows: size / res < 50 ? 2 : size / res > 500 ? 4 : 3 });
      }
    });
  };

  const showPopupLabel = (coordinate: string | Coordinate | undefined, name: string) => {
    const formattedName = truncate(name.replace(/(.{50})/g, '$1\n'), {
      length: 1000,
      omission: '...',
    });
    popUpHoverRef.current.show(coordinate, defaultPopUpStyle(formattedName));
  };

  const createPopupLabel = (
    features: GeoJSONFeature[],
    coordinate: string | Coordinate | undefined,
  ) => {
    let name = '';
    if (
      features.length === 1 &&
      (features[0] as Feature<Geometry>)?.get('type') !== POINT &&
      !(features[0] as Feature<Geometry>)?.get(ASSET_LABEL)
    ) {
      name = features[0].get('name');
    } else if (features.length > 0) {
      // Fences occupying the same spot, is a teardrop marker or a is microfence.
      if ((features[0] as Feature<Geometry>)?.get('type') === POINT) {
        name =
          features
            .map(feature => {
              return feature.get('name');
            })
            .join('/') ?? 'Unknown';
      } else if ((features[0] as Feature<Geometry>)?.get(ASSET_LABEL)) {
        // Is multiple assets.
        name =
          features.map((asset: Feature<Geometry>) => asset.get(ASSET_LABEL)).join('/') ?? 'Unknown';
      }
      if (name.replaceAll('/', '').length === 0) return;
    }

    if (!name && features.length === 1) {
      if (features[0].get('searchedCoordinates')) {
        // Is from search location.
        name = locationDisplayRef.current
          ? locationDisplayRef.current.label + '\n' + locationDisplayRef.current.coordinates
          : (features[0].get('isLastKnownLocation')
              ? 'Last known location'
              : features[0].get('searchedAddress') ?? 'UNKNOWN LOCATION') +
            '\n' +
            (features[0].get('searchedCoordinates') ?? 'UNKNOWN COORDINATES');
      } else if (features.length === 1) {
        // Is something else.
        name =
          (features[0].get('eventId')
            ? features[0].get('eventId')
            : features[0].get('beacon_id')) ?? 'Unknown';
      }

      if (!name) {
        if (popUpNameRef.current.length === 0 && features[0].get('id')) {
          const { id, layerId, type } = features[0];
          getGeofence(id, layerId, type).then(r => {
            if (r.data.length === 0) return;
            popUpNameRef.current = r.data[0].name ?? '';
            showPopupLabel(coordinate, popUpNameRef.current);
          });
        } else {
          showPopupLabel(coordinate, popUpNameRef.current);
        }
      }
    }
    if (!name) return;
    popUpNameRef.current = name;
    showPopupLabel(coordinate, features[0].get('name') ?? name);
  };

  const fenceSharesCommonPoint = (fences: GeoJSONFeature[]): boolean => {
    let extentDifference = Number.MAX_SAFE_INTEGER;
    const firstExent = fences[0]?.getGeometry()?.getExtent();
    for (let f = 1; f < fences.length; f++) {
      const currentExtent = fences[f]?.getGeometry()?.getExtent();
      if (firstExent && currentExtent) {
        extentDifference = getExtentDifference(firstExent, currentExtent);
        if (extentDifference > 1) return false;
      }
    }
    return true;
  };

  const updateClusters = (olmap: OlMap, pointLayer: AnimatedCluster) => {
    const newLayers: VectorLayer<VectorSource<Geometry>>[] = [];
    const zoom = olmap.getView().getZoom() || 0;
    const res = olmap.getView().getResolution() || 0;

    const nonClusteredMicrofences: Feature<Geometry>[] | undefined = microfenceLayer.current
      ?.getSource()
      .getFeatures()
      ?.filter((f: Feature<Geometry>) => f.get('features').length === 1)
      .map(f => f.get('features')[0]);
    if (nonClusteredMicrofences) {
      updateMicrofenceStyle(styleCache, nonClusteredMicrofences, res);
    }

    if (zoom > ZOOM_THRESHOLD - 1) {
      olmap.getView().setZoom(ZOOM_THRESHOLD - 1);
    }

    extentInDegreesRef.current =
      zoom <= initialZoomHeight
        ? initialExtentInDegrees
        : (res / Math.min(zoom, ZOOM_THRESHOLD - 1)) * 0.1;

    if (
      (hideClustersRef.current && numberOfFencesInClusterRef.current <= 1) ||
      zoom > FENCE_VIEWING_HEIGHT + 0.1
    ) {
      layersRef.current?.forEach(lyr => {
        const isCluster = lyr.source.getProperties().source instanceof Cluster;
        const id = isCluster ? lyr.source.get('id') : lyr.source.getProperties().source.get('id');
        if (
          id !== undefined &&
          !(
            olmap
              .getAllLayers()
              .find(
                layer =>
                  (isCluster ? layer.get('id') : layer.getProperties().source.get('id')) === id,
              ) ||
            newLayers.find(
              layer =>
                (isCluster ? layer.get('id') : layer.getProperties().source.get('id')) === id,
            )
          )
        ) {
          newLayers.push(lyr.source);
        }
      });

      if (
        pointLayer.get('id') !== undefined &&
        olmap.getAllLayers().find(layer => layer.getProperties().id === pointLayer.get('id'))
      ) {
        olmap.removeLayer(pointLayer);
      }
    }

    if (
      (!hideClustersRef.current || numberOfFencesInClusterRef.current > 1) &&
      zoom <= FENCE_VIEWING_HEIGHT + 0.1
    ) {
      let layerFound = false;
      layersRef.current?.forEach(lyr => {
        const isCluster = lyr.source.getProperties().source instanceof Cluster;
        const id = isCluster ? lyr.source.get('id') : lyr.source.getProperties().source.get('id');
        if (
          id !== undefined &&
          id !== selectedGeofence?.layerId &&
          !isCluster &&
          olmap.getAllLayers().find(layer => layer.getProperties().source.get('id') === id)
        ) {
          olmap.removeLayer(lyr.source);
        }
        if (id === selectedGeofence?.layerId) {
          layerFound = true;
        }
      });

      if (
        !layerFound &&
        pointLayer.get('id') !== undefined &&
        !olmap.getAllLayers().find(layer => layer.get('id') === pointLayer.get('id'))
      ) {
        newLayers.push(pointLayer);
      }
    }

    newLayers.forEach(l => olmap?.addLayer(l));

    olmap.getAllLayers().forEach(layer => {
      if (layer.get('id') === POINT) {
        if (zoom <= CLUSTER_MAX_ZOOM && layer.getSource().distance !== 200) {
          layer.getSource().distance = 200;
        } else if (zoom > CLUSTER_MAX_ZOOM && layer.getSource().distance !== 1) {
          layer.getSource().distance = 1;
        }
      }
    });
  };

  const setSelectedFeature = (olmap: OlMap, feature: Feature<Geometry>) => {
    setSelectedGeofence(undefined);
    setUserExtent(feature.get('geometry')?.getExtent());
    if (feature.get('layerId') !== POINT) {
      selectedFenceRef.current = feature;
    }
    if (popUpHoverRef.current) {
      popUpHoverRef.current.hide();
    }

    if (feature.get('layerId') !== POINT) {
      const { id, name, layerId, geomobyProperties, geomobyOverrides, points, zone } =
        selectedFenceRef.current.getProperties();
      const type = selectedFenceRef.current.type ?? selectedFenceRef.current.get('points')?.type;
      setSelectedGeofence({
        id,
        name,
        type,
        layerId,
        geomobyProperties,
        geomobyOverrides,
        zone,
      });
    }

    feature.set('selected', true);
    setUserExtent(feature.get('geometry')?.getExtent());

    const isMicrofence =
      selectedFenceRef.current?.getGeometry() instanceof Point &&
      selectedFenceRef.current?.get('layerId') === MICROFENCE_LAYER_ID &&
      selectedFenceRef.current.get('assetId');

    if (isMicrofence) {
      const microfenceAsset: SelectedAsset = {
        id: selectedFenceRef.current.get('assetId'),
        label: selectedFenceRef.current.get('fenceName'),
        prefix: 'Microfence',
        following: true,
      };
      setFollowingAsset(true);
      setSelectedAsset(microfenceAsset);
    } else {
      setSelectedGeofence(feature.getProperties());
    }
  };

  const setMapClickSelection = (
    m: OlMap,
    { excludeLayers }: { excludeLayers: VectorLayer<VectorSource<Geometry>>[] },
  ) => {
    const selectClick: Select = new Select({
      condition: click,
      filter: (feature, layer) => {
        if (!(layer instanceof VectorLayer || layer instanceof AnimatedCluster)) return false;

        const excluded = excludeLayers.includes(layer as VectorLayer<VectorSource<Geometry>>);
        return !excluded;
      },
      style: selectedGeoOrMicroFenceStyle(styleCache, { showActivity: true }),
    });

    selectClick.on('select', e => {
      selectedFenceRef.current = undefined;
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      deselectFences(m);
      if (assetSelectedRef.current) {
        assetSelectedRef.current = false;
        return;
      }

      e.preventDefault();
      const features: GeoJSONFeature[] = m.getFeaturesAtPixel(e.mapBrowserEvent.pixel) ?? [];

      if (!features || !features.length || features.length === 0) {
        selectedFenceRef.current = undefined;
        return;
      }

      let smallestExtentArea = Number.MAX_SAFE_INTEGER;
      let selectedFence: Feature<Geometry> = features[0];
      features.forEach(f => {
        const extent = f.getGeometry()?.getExtent();
        if (
          extent &&
          smallestExtentArea > Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
        ) {
          smallestExtentArea = Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2]);
          selectedFence = f;
        }
      });
      numberOfFencesInClusterRef.current = fenceSharesCommonPoint(features) ? 1 : features.length;

      let feature = selectedMicrofence
        ? liveMapStaticData?.microfences.find(
            f => f.feature.get('fenceId') === selectedMicrofence.id,
          )?.feature
        : selectedFence;

      const fenceId = feature?.get('id') ?? feature?.get('features')?.[0]?.get('id') ?? '';
      const layerId =
        feature?.get('layerId') ?? feature?.get('features')?.[0]?.get('layerId') ?? '';
      const featureIsPoint =
        feature?.get('type') === POINT ||
        (feature?.get('features')?.length === 1 &&
          feature?.get('features')[0].get('type') === POINT);
      if (layersRef.current && featureIsPoint) {
        layersRef.current.map(layer => {
          if (layer.id === layerId) {
            const foundFeature = layer.source
              .getSource()
              .getFeatures()
              .find(f => f.get('id') === fenceId);
            if (foundFeature) {
              feature = foundFeature;
            }
          }
        });
      } else {
        numberOfFencesInClusterRef.current = 1;
      }

      if (!feature?.get('geometry')?.getExtent()) return;
      if (feature?.get('features')?.length > 1) {
        const extent = [
          ...m.getCoordinateFromPixel(e.mapBrowserEvent.pixel),
          ...m.getCoordinateFromPixel(e.mapBrowserEvent.pixel),
        ];
        m.getView().fit(extent, {
          padding: [10, 10, 10, 10],
          duration: 100,
          maxZoom: CLUSTER_MAX_ZOOM + 1.5,
        });
        return;
      }
      if (featureIsPoint && feature?.get('features')?.length === 1) {
        setSelectedGeofence({
          id: fenceId ?? '',
          name: feature?.get('features')[0].get('name') ?? '',
          layerId: layerId ?? '',
          featureIsPoint,
        });
        const fenceType = feature?.get('features')[0].get('fenceType');
        getGeofence(fenceId, layerId, fenceType).then(updatedFence => {
          if (updatedFence) {
            setSelectedGeofence({
              ...updatedFence,
              layerId,
            });
            const newFence =
              fenceType === 'polygon'
                ? new Polygon(updatedFence.points.coordinates)
                : fenceType === 'multipolygon'
                ? new MultiPolygon(updatedFence.points.coordinates)
                : new LineString(updatedFence.points.coordinates);
            const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
            setUserExtent(extent);
          }
        });
      }

      selectedFenceRef.current = feature;
      setSelectedFromMap(true);

      if (layerId && layerId !== MICROFENCE_LAYER_ID) {
        const { id, name, layerId, geomobyProperties, geomobyOverrides, points, zone } =
          selectedFenceRef.current.getProperties();
        selectedFenceRef.current.set('selected', true);
        const type = selectedFenceRef.current.type ?? selectedFenceRef.current.get('points')?.type;
        setSelectedGeofence({
          id,
          name,
          type,
          layerId,
          geomobyProperties,
          geomobyOverrides,
          zone,
          selected: true,
        });
        setUserExtent(feature.get('geometry')?.getExtent());
        feature?.set('selected', true);
        m.getView().adjustZoom(-1);
        m.getView().adjustZoom(+1);
      } else if (layerId) {
        feature?.get('features')[0].set('selected', true);
        selectedFenceRef.current.set('selected', true);
        const microfenceAsset: SelectedAsset = {
          id: selectedFenceRef.current.get('assetId') ?? feature?.get('features')[0].get('assetId'),
          label:
            selectedFenceRef.current.get('fenceName') ??
            feature?.get('features')[0].get('fenceName'),
          prefix: 'Microfence',
          following: true,
        };
        setFollowingAsset(true);
        setSelectedAsset(microfenceAsset);
        setSelectedMicrofence(feature?.get('features')[0].getProperties());
        setUserExtent(feature?.get('features')[0].get('geometry')?.getExtent());
      }
    });
    m.addInteraction(selectClick);
    setSelectClick(selectClick);
  };

  if (resetStateRef)
    resetStateRef.current = () => {
      setSelectedAsset(undefined);
      setFollowingAsset(false);
      if (mapState) {
        mapState.assets = undefined;
        mapState.assetSrc.clear();
        mapState.fenceEvents = undefined;
        mapState.feSrc.clear();
        mapState.lineSrc.clear();
        mapState.labelSrc.clear();
        mapState.microfences.forEach(({ feature }) => {
          feature.unset(SENSED_ASSETS);
          feature.unset(SENSED_EXITED_ASSETS_IDS);
        });
        mapState.assetLyr.getSource().clear();
        mapState.feLyr.getSource().clear();
      }
    };

  return (
    <MapContainer id={mapType ?? 'unknown_map_id'}>
      <MapToolbar>
        {fencesLoading && <LoadIndicator what="geofences" />}

        {selectedAsset && (
          <Follow
            following={followingAsset}
            onClick={() => {
              selectedAsset.following = !selectedAsset.following;
              setFollowingAsset(selectedAsset.following);
            }}
          />
        )}

        {mapType?.includes('OUTDOOR') && (
          <ChangeMapSourceType current={mapSource} setType={setMapSource} />
        )}

        <ZoomIn
          onClick={() => {
            mapState?.map?.getView().adjustZoom(0.5);
            setSelectedGeofence(undefined);
          }}
        />

        <ZoomOut
          onClick={() => {
            mapState?.map?.getView().adjustZoom(-0.5);
            setSelectedGeofence(undefined);
          }}
        />
      </MapToolbar>
      {sensedAssets && <SensedAssetsPopover sensedAssets={sensedAssets} />}
      {localBeacons && <LocalBeaconsPopover localBeacons={localBeacons} />}
    </MapContainer>
  );
}
