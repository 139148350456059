import View from 'ol/View';
import { Coordinate } from 'ol/coordinate';
import { animate } from 'popmotion';
import { Map as OlMap } from 'ol';
import { ZOOM_THRESHOLD } from '../../util/constants';

export function flyTo(view: View, to: Coordinate, done: (d: boolean) => void) {
  const duration = 1000;
  const zoom = view.getZoom();
  let parts = 2;
  let called = false;
  function callback(complete: boolean) {
    --parts;
    if (called) {
      return;
    }
    if (parts === 0 || !complete) {
      called = true;
      done(complete);
    }
  }
  view.animate(
    {
      center: to,
      duration: duration,
    },
    callback,
  );
  view.animate(
    {
      zoom: (zoom ?? 0) - 1,
      duration: duration / 2,
    },
    {
      zoom: zoom,
      duration: duration / 2,
    },
    callback,
  );
}

export function panTo(view: View, to: Coordinate, done: (d: boolean) => void) {
  view.animate({
    center: to,
    duration: 300,
  });
}

export const getCoordinateDifference = (from: number[], to: number[]): number => {
  return Math.abs(from[0] - to[0]) + Math.abs(from[1] - to[1]);
};

export const animateToLocation = (
  [lon1, lat1, lon2, lat2]: [number, number, number, number],
  mapRef: OlMap,
): void => {
  const sizeOfFence = getCoordinateDifference([lon1, lat1], [lon2, lat2]);
  const duration = Math.min(
    6000,
    Math.max(
      300,
      getCoordinateDifference(
        mapRef.getView().getCenter() ?? [0, 0],
        [lon1, lat1, lon2, lat2] ?? [0, 0],
      ) / 1000,
    ),
  );
  mapRef.getView().animate(
    {
      center: [lon1, lat1, lon2, lat2],
      zoom: duration > 300 ? 9 - duration / 1000 : mapRef.getView().getZoom(),
      duration: duration,
    },
    () => {
      mapRef.getView().animate({
        center: [lon1, lat1, lon2, lat2],
        zoom: sizeOfFence === 0 ? 18 : sizeOfFence < 10000 ? 13 : sizeOfFence < 100000 ? 10 : 8.5, // Not very sophisticated but we can change it in the future (to some ratio maybe).
        duration: 2000,
      });
    },
  );
};

export function animateCoordinates(
  from: Coordinate,
  to: Coordinate,
  durationMs: number,
  setCoord: (c: Coordinate) => void,
): void {
  animate({
    from: { x: from[0], y: from[1] },
    to: { x: to[0], y: to[1] },
    duration: durationMs,
    onUpdate: ({ x, y }) => setCoord([x, y]),
  });
}

export function fitToExtent(view: View, extent: number[]) {
  view.fit(extent, {
    padding: [10, 10, 10, 10],
    duration: 100,
    maxZoom: ZOOM_THRESHOLD - 4,
  });
}
