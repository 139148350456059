import { ArrowBack, ArrowForward } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack } from '@mui/material';
import { BACKGROUND } from '../../../../Style/GeoMobyBaseTheme';
import {
  EBTListenerTypeId,
  EBTTriggerTypeOptionsId,
  FenceZone,
  GeofenceEntityTypeId,
} from '../../../../util/enums';
import { EBTProps } from '../../Props';
import SelectAFence from './SelectAFence';
import SelectAFenceType from './SelectAFenceType';
import SelectALayer from './SelectALayer';
import SelectAZone from './SelectAZone';

const LocationTriggerOption = (
  props: EBTProps & {
    showLocationTriggerOption: boolean;
  },
) => {
  const isCrossing =
    props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.LeftToRightCrossing ||
    props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.RightToLeftCrossing;
  const isClearedZone = props.selectedGeofenceType?.id === GeofenceEntityTypeId.Multipolygon;
  const isPolygonZone =
    props.selectedZone?.id === FenceZone.buffer ||
    props.selectedZone?.id === FenceZone.breach ||
    props.selectedZone?.id === FenceZone.loading ||
    props.selectedZone?.id === FenceZone.dumping ||
    props.selectedZone?.id === FenceZone.maintenance;

  return (
    <>
      {props.showLocationTriggerOption && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              background: BACKGROUND,
            }}
          >
            {props.selectedLocationListenerType === EBTListenerTypeId.Layer && props.selectedLayer
              ? `Location: ${props.selectedLayer.name}`
              : props.selectedLocationListenerType === EBTListenerTypeId.Geofence &&
                props.selectedFeature
              ? `Location: ${props.selectedLayer?.name}/${props.selectedFeature.name}`
              : `Select a Location`}
          </AccordionSummary>
          <AccordionDetails
            style={{
              background: BACKGROUND,
            }}
          >
            <Stack direction="column" spacing={1}>
              {/* Select a layer */}
              <Box style={{ height: '325px' }}>{SelectALayer(props)}</Box>

              <Grid
                container
                style={{
                  height: '65px',
                  display: 'grid',
                  gap: '2%',
                  gridTemplateColumns: '49% 49%',
                }}
              >
                {/* Select a zone */}
                <Box style={{ height: '50px' }}>
                  {SelectAZone({
                    ...props,
                    isCrossing: isCrossing,
                    isClearedZone: isClearedZone,
                  })}
                </Box>
                {/* Select a fence type */}
                <Box style={{ height: '50px' }}>
                  {SelectAFenceType({
                    ...props,
                    isCrossing: isCrossing,
                    isPolygonZone: isPolygonZone,
                  })}
                </Box>
              </Grid>
              {/* Select a fence */}
              <Box
                style={{
                  height:
                    props.selectedLocationListenerType === EBTListenerTypeId.Layer
                      ? '50px'
                      : '474px',
                }}
              >
                {SelectAFence({
                  ...props,
                  isCrossing: isCrossing,
                })}
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
export default LocationTriggerOption;
