import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { BACKGROUND_OFFSET } from '../../../Style/GeoMobyBaseTheme';
import { EBTNotifierTypeId } from '../../../util/enums';
import { useMobile } from '../../../util/useMobile';
import { getEventTileIcon, getEventTitle, getListeners, getTypeTitle } from '../Helpers';
import { EBTProps, EmailOrWebhookListProps, ListenersProps } from '../Props';
import { EBT } from '../types';

const EmailList = (props: EBTProps & EmailOrWebhookListProps) => {
  const isMobile = useMobile();
  return (
    <>
      {props.availableEBTs
        .filter(e => e.notifierType === EBTNotifierTypeId.Email)
        ?.map(email => (
          <Box
            p={2}
            key={email.id}
            style={{
              width: '100%',
              height: '200px',
              marginBottom: '25px',
            }}
          >
            <Paper
              elevation={4}
              style={{
                height: 'auto',
                overflow: 'hidden',
                background: email.id === props.eventIds?.emailId ? '#1E3748' : BACKGROUND_OFFSET,
              }}
            >
              <Stack
                p={4}
                direction="column"
                spacing={1}
                style={{
                  overflowX: 'hidden',
                  paddingRight: '50px',
                  paddingBottom: isMobile ? '4px' : '20px',
                }}
              >
                <Grid
                  container
                  direction="column"
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: isMobile ? '50% 60%' : '9% 31% 53% 1%',
                    width: '100%',
                  }}
                >
                  {!isMobile && (
                    <Grid item>
                      <span></span>
                      {getEventTileIcon(
                        `${getTypeTitle(
                          email.listenerType,
                          props.boundariesById[email.listenerId]?.type,
                          !!props.geofencesById[email.listenerId],
                          !!props.layersById[email.listenerId],
                          !!props.microfencesById[email.listenerId],
                        ).toUpperCase()}_
                        ${getEventTitle(
                          email,
                          props.boundariesById[email.listenerId]?.higherIsEnter,
                          props.geofencesById[email.listenerId]?.type,
                        ).toUpperCase()}`,
                        !!isMobile,
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    style={{
                      overflowX: 'auto',
                      maxWidth: isMobile ? '90%' : 'auto',
                      height: '160px',
                    }}
                  >
                    <Box>
                      <Tooltip title={email.subject}>
                        <Typography variant="h6">{email.subject}</Typography>
                      </Tooltip>
                      <Tooltip title={email.recipients.join(',')}>
                        <Typography>Recipients: {email.recipients.join(',')}</Typography>
                      </Tooltip>
                      <Tooltip title={email.text}>
                        <Typography>Text: {email.text}</Typography>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    style={{
                      overflowY: 'auto',
                      height: '160px',
                    }}
                  >
                    {getListeners({
                      ...props,
                      ebt: email,
                      boundariesById: props.boundariesById,
                      geofencesById: props.geofencesById,
                      layersById: props.layersById,
                      microfencesById: props.microfencesById,
                    } as unknown as ListenersProps)}
                  </Grid>

                  {!isMobile && (
                    <Grid
                      item
                      container
                      direction="row"
                      spacing={2}
                      style={{
                        height: '100px',
                        marginTop: 'auto',
                      }}
                    >
                      <Grid item>
                        <Tooltip title={props.isLoading ? '' : 'Edit'}>
                          <Button
                            size="small"
                            disabled={props.isLoading}
                            onClick={() => {
                              props.editEBT(email, EBTNotifierTypeId.Email);
                            }}
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip title={props.isLoading ? '' : 'Delete'}>
                          <Button
                            size="small"
                            disabled={props.isLoading}
                            onClick={() => {
                              props.setDeleting({ id: email?.id, type: EBTNotifierTypeId.Email });
                            }}
                          >
                            <Delete />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Stack>
              {isMobile && (
                <Grid
                  item
                  container
                  direction="column"
                  style={{
                    height: '40px',
                    display: 'grid',
                    gridTemplateColumns: '60% 10% 10% 10%',
                    paddingLeft: '7%',
                    width: '100%',
                  }}
                >
                  <Grid item>
                    {getEventTileIcon(
                      `${getTypeTitle(
                        email.listenerType,
                        props.boundariesById[email.listenerId]?.type,
                        !!props.geofencesById[email.listenerId],
                        !!props.layersById[email.listenerId],
                        !!props.microfencesById[email.listenerId],
                      ).toUpperCase()}_
                        ${getEventTitle(
                          email,
                          props.boundariesById[email.listenerId]?.higherIsEnter,
                          props.geofencesById[email.listenerId]?.type,
                        ).toUpperCase()}`,
                      !!isMobile,
                    )}
                  </Grid>

                  <Grid item>
                    <Tooltip title={props.isLoading ? '' : 'Edit'}>
                      <Button
                        size="small"
                        disabled={props.isLoading}
                        onClick={() => {
                          props.editEBT(email, EBTNotifierTypeId.Email);
                        }}
                      >
                        <Edit />
                      </Button>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Tooltip title={props.isLoading ? '' : 'Delete'}>
                      <Button
                        size="small"
                        disabled={props.isLoading}
                        onClick={() => {
                          props.setDeleting({ id: email?.id, type: EBTNotifierTypeId.Email });
                        }}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Box>
        ))}
    </>
  );
};
export default EmailList;
