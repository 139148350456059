import { NearMe } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { MutableRefObject } from 'react';
import { lineLimitStyle, PRIMARY, WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import {
  FenceGeometryType,
  FenceZone,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { geometryTypeOfEntity } from '../../../commons';
import { OverridesTable } from '../../../Editor/Sidebar/Geofence/GeomobyOverrides';
import { PropertiesTable } from '../../../Editor/Sidebar/Geofence/GeomobyProperties';
import { getZoneIcon } from '../../../Helpers';
import { GeofenceFilter } from '../../../types';
import { SearchListProps } from '../../Props';

export const useSelectedGeofence = (
  props: SearchListProps & {
    pageRef: MutableRefObject<number>;
    paginateGeofences: (filter?: GeofenceFilter) => Promise<void>;
  },
) => {
  return (
    <>
      {props.selectedGeofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Box color="primary.main">{getZoneIcon(props.selectedGeofence.zone)}</Box>

          <Grid
            container
            direction="row"
            style={{
              width: '100%',
              marginBottom:
                (geometryTypeOfEntity(props.selectedGeofence) !== FenceGeometryType.Multipolygon &&
                  props.selectedGeofence.zone === FenceZone.none) ||
                geometryTypeOfEntity(props.selectedGeofence) === FenceGeometryType.Line
                  ? '0px'
                  : '10px',
              marginTop:
                (geometryTypeOfEntity(props.selectedGeofence) !== FenceGeometryType.Multipolygon &&
                  props.selectedGeofence.zone === FenceZone.none) ||
                geometryTypeOfEntity(props.selectedGeofence) === FenceGeometryType.Line
                  ? '0px'
                  : '-37px',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip title={'NAVIGATE TO'}>
              <IconButton
                color="primary"
                onClick={() => {
                  props.setNavigateTo(props.selectedGeofence?.id);
                }}
              >
                <NearMe />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={1}
            marginLeft={0}
            justifyContent="left"
            alignItems="center"
          >
            <Tooltip title={props.selectedGeofence.name} style={lineLimitStyle}>
              <Typography variant="h5">{props.selectedGeofence.name}</Typography>
            </Tooltip>
          </Grid>

          <Grid
            container
            direction="column"
            style={{
              marginBottom: '10px',
              width: 'fit-content',
            }}
          >
            <Button
              style={{
                marginLeft: '-7px',
                color: WHITE,
              }}
              onClick={() => {
                props.setDeselectFence(true);
                props.setSelectedGeofence(undefined);
                props.setSelectedMicrofence(undefined);
                props.setSearchType({
                  id: SearchTypeIDs.Geofences,
                  value: SearchTypeValue.Geofences,
                });
                props.setGeofenceFilter(undefined);
                props.pageRef.current = 1;
                props.setLayerFilter({
                  layer: {
                    name:
                      props.layers.find(lyr => lyr.id === props.selectedGeofence?.layerId)?.name ??
                      'Unknown',
                    id: props.selectedGeofence?.layerId,
                  },
                });
                props.paginateGeofences({
                  ...props.geofenceFilter,
                  layerId: props.selectedGeofence?.layerId,
                } as GeofenceFilter);
                props.setRefreshSearch(true);
              }}
            >
              {props.layers.find(lyr => lyr.id === props.selectedGeofence?.layerId)?.name ??
                'Unknown'}
            </Button>
          </Grid>

          {props.selectedGeofence.geomobyProperties && (
            <>
              <Tooltip title={'Geofence Properties'}>
                <Typography style={{ color: PRIMARY }}>Geofence Properties</Typography>
              </Tooltip>
              <PropertiesTable
                rows={
                  Array.from(
                    Object.entries(props.selectedGeofence.geomobyProperties)
                      .sort()
                      .map(([property, value], index) => {
                        return { index, property, value: value };
                      }),
                  ) as { index: number; property: string; value: string }[]
                }
              ></PropertiesTable>
            </>
          )}

          {props.selectedGeofence.geomobyOverrides && (
            <>
              <Tooltip title={'Override Rules'}>
                <Typography style={{ color: PRIMARY }}>Override Rules</Typography>
              </Tooltip>
              <OverridesTable rows={props.selectedGeofence.geomobyOverrides}></OverridesTable>
            </>
          )}
        </Paper>
      )}
    </>
  );
};
