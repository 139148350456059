import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { EBTNotifierTypeValue } from '../../../util/enums';
import { getNotificationTypeIcon } from '../Helpers';
import { EBTProps } from '../Props';
import { EBTNotifierTypes } from '../values';

const SelectAnEventNotification = (
  props: EBTProps & {
    showSelectAnEventNotification: boolean;
  },
) => {
  return (
    <>
      {props.showSelectAnEventNotification && (
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 'calc(100% - 10vw)',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="trigger-notification">Event Notification Type</InputLabel>
          <Select
            fullWidth
            labelId="trigger-notification"
            id="trigger-notification-dropdown"
            value={props.selectedNotifierType?.value ?? ''}
            label="Event Notification Type"
            disabled={!!props.eventIds}
            onChange={e => {
              const notificationType = EBTNotifierTypes.find(
                notificationType =>
                  notificationType.value === (e.target.value as EBTNotifierTypeValue),
              );
              props.setSelectedNotifierType(notificationType);
            }}
          >
            {EBTNotifierTypes.map(notificationType => (
              <MenuItem
                key={notificationType.id}
                value={notificationType.value}
                disabled={notificationType.disabled}
              >
                <Tooltip title={notificationType.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {getNotificationTypeIcon(notificationType.id)}
                    <span
                      style={{
                        padding: '10px',
                      }}
                    ></span>{' '}
                    {notificationType.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default SelectAnEventNotification;
