import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import {
  EBTListenerTypeId,
  FenceZone,
  FenceZoneValue,
  GeofenceEntityTypeId,
  GeofenceEntityTypeValue,
} from '../../../../util/enums';
import { getZoneIcon } from '../../../Map/Helpers';
import { FenceZoneType } from '../../../Map/types';
import { FenceZoneTypes } from '../../../Map/values';
import { GeofenceType } from '../../types';
import { ALL, ALL_GEOFENCE_ZONES } from '../../../../util/constants';
import { EBTProps } from '../../Props';

const SelectAZone = (
  props: EBTProps & {
    isCrossing: boolean;
    isClearedZone: boolean;
  },
) => {
  return (
    <>
      <FormControl
        style={{
          width: '100%',
          marginBottom: '15px',
        }}
      >
        <InputLabel id="zone-type-option">Geofence Zone</InputLabel>
        <Select
          fullWidth
          labelId="zone-type-action"
          id="zone-type-dropdown"
          value={props.selectedZone?.value}
          label="Geofence Zone"
          disabled={
            props.isCrossing ||
            props.isClearedZone ||
            (!!props.eventIds && props.selectedLocationListenerType === EBTListenerTypeId.Geofence)
          }
          style={{
            height: '60px',
          }}
          onChange={e => {
            const zoneType = FenceZoneTypes.find(
              z => z.value === (e.target.value as FenceZoneValue),
            );
            props.featurePageRef.current = 1;
            props.setSearchString(undefined);
            props.setSelectedLocationListenerType(EBTListenerTypeId.Layer);
            props.setAvailableFeatures([]);

            if (!zoneType) {
              props.setSelectedZone({ id: ALL, value: ALL_GEOFENCE_ZONES });
              return;
            }
            props.setSelectedZone(zoneType);
            if (
              !props.isCrossing &&
              zoneType.id !== FenceZone.none &&
              zoneType.id !== FenceZone.cleared
            ) {
              props.setSelectedGeofenceType({
                id: GeofenceEntityTypeId.Polygon,
                value: GeofenceEntityTypeValue.Polygon,
              });
            }
          }}
        >
          {[{ id: ALL, value: ALL_GEOFENCE_ZONES }, ...FenceZoneTypes].map(zone => (
            <MenuItem key={zone.id} value={zone.value}>
              <Grid
                container
                style={
                  zone.id === FenceZone.breach ||
                  zone.id === FenceZone.buffer ||
                  zone.id === FenceZone.cleared
                    ? {
                        // We can ammend this when the other zones have an icon https://geomoby.atlassian.net/browse/LTP-1165
                        display: 'grid',
                        gap: '20px',
                        gridTemplateColumns: '30px 200px',
                      }
                    : {
                        marginLeft: '50px',
                      }
                }
              >
                {zone.id !== ALL ? getZoneIcon(zone.id as FenceZone) : ''}
                {zone.value}
              </Grid>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default SelectAZone;
