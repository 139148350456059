import { getArea, getLength } from 'ol/sphere';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import { M_TO_FT, M_TO_KM, M_TO_MI } from '../../../util/constants';
import { Geometry, LineString, MultiPolygon } from 'ol/geom';
import { FenceGeometryType, MeasurementType } from '../../../util/enums';
import { getCenter, getHeight, getWidth } from 'ol/extent';
import Style, { StyleFunction } from 'ol/style/Style';
import RenderFeature from 'ol/render/Feature';
import { Feature } from 'ol';
import { geometryTypeOfEntity } from '../commons';
import { GridRowData } from '@material-ui/data-grid';
import VectorSource from 'ol/source/Vector';

export function calculateArea(polygon: Polygon, measurementType: MeasurementType | undefined) {
  const area = getArea(polygon);
  switch (measurementType) {
    case MeasurementType.FT2:
      return (area * M_TO_FT * M_TO_FT).toFixed(2) + ' ' + 'ft<sup>2</sup>';
    case MeasurementType.KM2:
      return (area * M_TO_KM * M_TO_KM).toFixed(2) + ' km<sup>2</sup>';
    case MeasurementType.MI2:
      return (area * M_TO_MI * M_TO_MI).toFixed(2) + ' mi<sup>2</sup>';
    default:
      return area.toFixed(2) + ' ' + 'm<sup>2</sup>';
  }
}

export function calculateMeasurementDistance(
  line: LineString,
  measurementType: MeasurementType | undefined,
) {
  const length = getLength(line);
  switch (measurementType) {
    case MeasurementType.FT:
      return (length * M_TO_FT).toFixed(2) + ' ' + measurementType.toLowerCase();
    case MeasurementType.KM:
      return (length * M_TO_KM).toFixed(2) + ' ' + measurementType.toLowerCase();
    case MeasurementType.MI:
      return (length * M_TO_MI).toFixed(2) + ' ' + measurementType.toLowerCase();
    default:
      return length.toFixed(2) + ' ' + MeasurementType.M.toLowerCase();
  }
}

export function calculateCenterOfFence(
  geometry: LineString | Polygon | MultiPolygon,
  type: FenceGeometryType | undefined,
) {
  let center: number[];
  const coordinates =
    type === FenceGeometryType.Polygon
      ? geometry.getCoordinates()[0].slice(1)
      : type === FenceGeometryType.Line
      ? (geometry as LineString).getCoordinateAt(0.5)
      : type === FenceGeometryType.Multipolygon
      ? (geometry.getCoordinates() as number[][][])[0][0]
      : [];

  let minRadius = 1;
  if (type === FenceGeometryType.Polygon) {
    let x = 0;
    let y = 0;
    let i = 0;

    (coordinates as number[][]).forEach(coordinate => {
      if (Array.isArray(coordinate) && coordinate.length > 1) {
        x += coordinate[0];
        y += coordinate[1];
        i++;
      }
    });
    center = [x / i, y / i];
  } else if (type === FenceGeometryType.Multipolygon) {
    center = getCenter(geometry.getExtent());
  } else if (type === FenceGeometryType.Line) {
    center = (geometry as LineString).getCoordinateAt(0.5);
  } else {
    center = getCenter(geometry.getExtent());
  }
  let sqDistances: number[] = [];
  if (Array.isArray(coordinates)) {
    sqDistances = (
      type === FenceGeometryType.Polygon || type === FenceGeometryType.Multipolygon
        ? (coordinates as number[][])
        : (coordinates as number[])
    ).map(coordinate => {
      if (Array.isArray(coordinate) && coordinate.length > 1) {
        const dx = coordinate[0] - center[0];
        const dy = coordinate[1] - center[1];
        return dx * dx + dy * dy;
      }
      return 0;
    });
    if (Array.isArray(sqDistances)) {
      minRadius = Math.sqrt(Math.max(...sqDistances)) / 3;
    }
  } else {
    minRadius = Math.max(getWidth(geometry.getExtent()), getHeight(geometry.getExtent())) / 3;
  }
  return {
    center: center,
    coordinates: coordinates,
    minRadius: minRadius,
    sqDistances: sqDistances,
  };
}

export function defaultScaleRotFenceStyle(cache: Map<string, Style[]>): StyleFunction {
  return (feature: Feature<Geometry> | RenderFeature, p1) => {
    feature.get('features').forEach((modifyFeature: Feature<Geometry>) => {
      const modifyGeometry = modifyFeature.get('modifyGeometry');
      if (modifyGeometry && feature.getGeometry() !== undefined) {
        const geometry = feature.getGeometry();
        if (!geometry) return;
        const point = getCenter(geometry.getExtent());
        let modifyPoint = modifyGeometry.point;
        if (!modifyPoint) {
          // save the initial geometry and vertex position
          modifyPoint = point;
          modifyGeometry.point = modifyPoint;
          modifyGeometry.geometry0 = modifyGeometry.geometry;
          // get anchor and minimum radius of vertices to be used
          const result = calculateCenterOfFence(
            modifyGeometry.geometry0,
            geometryTypeOfEntity(modifyFeature.getProperties() as GridRowData),
          );
          modifyGeometry.center = result.center;
          modifyGeometry.minRadius = result.minRadius;
        }

        const center = modifyGeometry.center;
        const minRadius = modifyGeometry.minRadius;
        let dx, dy;
        dx = modifyPoint[0] - center[0];
        dy = modifyPoint[1] - center[1];
        const initialRadius = Math.sqrt(dx * dx + dy * dy);
        if (initialRadius > minRadius && Array.isArray(point)) {
          const initialAngle = Math.atan2(dy, dx);
          dx = point[0] - center[0];
          dy = point[1] - center[1];
          const currentRadius = Math.sqrt(dx * dx + dy * dy);
          if (currentRadius > 0) {
            const currentAngle = Math.atan2(dy, dx);
            const geometry = modifyGeometry.geometry0.clone();
            geometry.scale(currentRadius / initialRadius, undefined, center);
            geometry.rotate(currentAngle - initialAngle, center);
            modifyGeometry.geometry = geometry;
          }
        }
      }
    });
  };
}

export function featureContainsFeature(
  geometry: Geometry,
  layerSource: VectorSource<Geometry> | undefined,
): Feature<Geometry> | undefined {
  if (!layerSource) return;
  const containedFeatures = layerSource.getFeatures().filter(f => {
    const ex = f.getGeometry()?.getExtent();
    if (f.getProperties().geometry.getType().toLowerCase() === FenceGeometryType.Polygon) {
      const coords = (f.getGeometry() as Polygon).getCoordinates()[0];
      if (!coords) return false;
      return coords.every((coord: number[]) => geometry.intersectsCoordinate(coord));
    }
  });

  return containedFeatures?.length === 1 ? containedFeatures[0] : undefined;
}
