import {
  GeofenceEntityTypeId,
  EBTTriggerTypeId,
  EBTTriggerTypeValue,
  EBTOrderByTypeId,
  EBTOrderByTypeValue,
  EnteredTypeValue,
  EnteredTypeId,
  EBTTriggerTypeOptionsValue,
  EBTTriggerTypeOptionsId,
  EBTNotifierTypeValue,
  EBTNotifierTypeId,
  EBTListenerTypeValue,
  OrderTypeId,
  OrderTypeValue,
  EBTListenerTypeId,
  GeofenceEntityTypeValue,
} from '../../util/enums';
import {
  EBTNotifierType,
  EnteredType,
  GeofenceType,
  ListenerType,
  OrderByType,
  OrderType,
  TriggerType,
} from './types';

export const EBTNotifierTypes: EBTNotifierType[] = [
  { id: EBTNotifierTypeId.Email, value: EBTNotifierTypeValue.Email },
  { id: EBTNotifierTypeId.Notification, value: EBTNotifierTypeValue.Notification, disabled: true },
  { id: EBTNotifierTypeId.Webhook, value: EBTNotifierTypeValue.Webhook },
];

export const EnteredTypes: EnteredType[] = [
  {
    id: EnteredTypeId.Above,
    value: EnteredTypeValue.Above,
    booleanValue: true,
    listenerTypeId: EBTListenerTypeId.Sensor,
  },
  {
    id: EnteredTypeId.Below,
    value: EnteredTypeValue.Below,
    booleanValue: false,
    listenerTypeId: EBTListenerTypeId.Sensor,
  },
  {
    id: EnteredTypeId.EnterGeofence,
    value: EnteredTypeValue.Enter,
    booleanValue: true,
    listenerTypeId: EBTListenerTypeId.Geofence,
  },
  {
    id: EnteredTypeId.ExitGeofence,
    value: EnteredTypeValue.Exit,
    booleanValue: false,
    listenerTypeId: EBTListenerTypeId.Geofence,
  },
  {
    id: EnteredTypeId.DwellGeofence,
    value: EnteredTypeValue.Dwell,
    booleanValue: true,
    listenerTypeId: EBTListenerTypeId.Geofence,
  },
  {
    id: EnteredTypeId.EnteredMicrofence,
    value: EnteredTypeValue.Enter,
    booleanValue: true,
    listenerTypeId: EBTListenerTypeId.Microfence,
  },
  {
    id: EnteredTypeId.ExitMicrofence,
    value: EnteredTypeValue.Exit,
    booleanValue: false,
    listenerTypeId: EBTListenerTypeId.Microfence,
  },
  {
    id: EnteredTypeId.Left,
    value: EnteredTypeValue.LeftToRight,
    booleanValue: true,
    listenerTypeId: EBTListenerTypeId.Geofence,
    geofenceTypeId: GeofenceEntityTypeId.Line,
  },
  {
    id: EnteredTypeId.Right,
    value: EnteredTypeValue.RightToLeft,
    booleanValue: false,
    listenerTypeId: EBTListenerTypeId.Geofence,
    geofenceTypeId: GeofenceEntityTypeId.Line,
  },
];

export const GeofenceTypes: GeofenceType[] = [
  { id: GeofenceEntityTypeId.Line, value: GeofenceEntityTypeValue.Tripwire },
  { id: GeofenceEntityTypeId.Multipolygon, value: GeofenceEntityTypeValue.Multipolygon },
  { id: GeofenceEntityTypeId.Polygon, value: GeofenceEntityTypeValue.Polygon },
];

export const ListenerTypes: ListenerType[] = [
  { id: EBTListenerTypeId.Geofence, value: EBTListenerTypeValue.Location },
  { id: EBTListenerTypeId.Microfence, value: EBTListenerTypeValue.Proximity },
  { id: EBTListenerTypeId.Sensor, value: EBTListenerTypeValue.Sensor },
];

export const OrderTypes: OrderType[] = [
  { id: OrderTypeId.ASC, value: OrderTypeValue.Ascending },
  { id: OrderTypeId.DESC, value: OrderTypeValue.Descending },
];

export const OrderByTypes: OrderByType[] = [
  { id: EBTOrderByTypeId.Entered, value: EBTOrderByTypeValue.EventTriggerOption },
  { id: EBTOrderByTypeId.ListenerType, value: EBTOrderByTypeValue.EventTriggerType },
  { id: EBTOrderByTypeId.GeofenceType, value: EBTOrderByTypeValue.GeofenceType },
  {
    id: EBTOrderByTypeId.Method,
    value: EBTOrderByTypeValue.Method,
    parentTypeId: EBTNotifierTypeId.Webhook,
  },
  { id: EBTOrderByTypeId.NotifierType, value: EBTOrderByTypeValue.NotificationType },
  {
    id: EBTOrderByTypeId.Recipients,
    value: EBTOrderByTypeValue.Recipients,
    parentTypeId: EBTNotifierTypeId.Email,
  },
  {
    id: EBTOrderByTypeId.Subject,
    value: EBTOrderByTypeValue.Subject,
    parentTypeId: EBTNotifierTypeId.Email,
  },
  {
    id: EBTOrderByTypeId.Text,
    value: EBTOrderByTypeValue.Text,
    parentTypeId: EBTNotifierTypeId.Email,
  },
  {
    id: EBTOrderByTypeId.Url,
    value: EBTOrderByTypeValue.Url,
    parentTypeId: EBTNotifierTypeId.Webhook,
  },
];

export const TriggerTypes: TriggerType[] = [
  {
    id: EBTTriggerTypeId.Battery,
    value: EBTTriggerTypeValue.Battery,
    options: [
      { id: EBTTriggerTypeOptionsId.Above, value: EBTTriggerTypeOptionsValue.Above },
      { id: EBTTriggerTypeOptionsId.Below, value: EBTTriggerTypeOptionsValue.Below },
    ],
  },
  {
    id: EBTTriggerTypeId.Custom,
    value: EBTTriggerTypeValue.Custom,
    disabled: true,
    options: [
      { id: EBTTriggerTypeOptionsId.Number, value: EBTTriggerTypeOptionsValue.Number },
      { id: EBTTriggerTypeOptionsId.String, value: EBTTriggerTypeOptionsValue.String },
    ],
  },
  {
    id: EBTTriggerTypeId.EnvironmentalMonitoring,
    value: EBTTriggerTypeValue.EnvironmentalMonitoring,
    disabled: true,
    options: [],
  },
  {
    id: EBTTriggerTypeId.Extensometer,
    value: EBTTriggerTypeValue.Extensometer,
    disabled: true,
    options: [],
  },
  {
    id: EBTTriggerTypeId.Inactivity,
    value: EBTTriggerTypeValue.Inactivity,
    disabled: true,
    options: [],
  },
  {
    id: EBTTriggerTypeId.Location,
    value: EBTTriggerTypeValue.Location,
    options: [
      {
        id: EBTTriggerTypeOptionsId.Dwell,
        value: EBTTriggerTypeOptionsValue.Dwell,
        disabled: true,
      },
      { id: EBTTriggerTypeOptionsId.Entry, value: EBTTriggerTypeOptionsValue.Entry },
      { id: EBTTriggerTypeOptionsId.Exit, value: EBTTriggerTypeOptionsValue.Exit },
      {
        id: EBTTriggerTypeOptionsId.LeftToRightCrossing,
        value: EBTTriggerTypeOptionsValue.LeftToRightCrossing,
      },
      {
        id: EBTTriggerTypeOptionsId.RightToLeftCrossing,
        value: EBTTriggerTypeOptionsValue.RightToLeftCrossing,
      },
    ],
  },
  {
    id: EBTTriggerTypeId.ManDown,
    value: EBTTriggerTypeValue.ManDown,
    disabled: true,
    options: [],
  },
  {
    id: EBTTriggerTypeId.Proximity,
    value: EBTTriggerTypeValue.Proximity,
    options: [
      { id: EBTTriggerTypeOptionsId.Entry, value: EBTTriggerTypeOptionsValue.Entry },
      { id: EBTTriggerTypeOptionsId.Exit, value: EBTTriggerTypeOptionsValue.Exit },
    ],
  },

  {
    id: EBTTriggerTypeId.Scheduler,
    value: EBTTriggerTypeValue.Scheduler,
    disabled: true,
    options: [{ id: EBTTriggerTypeOptionsId.NewRule, value: EBTTriggerTypeOptionsValue.NewRule }],
  },
  {
    id: EBTTriggerTypeId.Spo2,
    value: EBTTriggerTypeValue.Spo2,
    options: [
      { id: EBTTriggerTypeOptionsId.Above, value: EBTTriggerTypeOptionsValue.Above },
      { id: EBTTriggerTypeOptionsId.Below, value: EBTTriggerTypeOptionsValue.Below },
    ],
  },
  {
    id: EBTTriggerTypeId.Temperature,
    value: EBTTriggerTypeValue.Temperature,
    options: [
      { id: EBTTriggerTypeOptionsId.Above, value: EBTTriggerTypeOptionsValue.Above },
      { id: EBTTriggerTypeOptionsId.Below, value: EBTTriggerTypeOptionsValue.Below },
    ],
  },
  {
    id: EBTTriggerTypeId.Webhook,
    value: EBTTriggerTypeValue.Webhook,
    disabled: true,
    options: [
      { id: EBTTriggerTypeOptionsId.Get, value: EBTTriggerTypeOptionsValue.Get },
      { id: EBTTriggerTypeOptionsId.Post, value: EBTTriggerTypeOptionsValue.Post },
    ],
  },
  {
    id: EBTTriggerTypeId.WelfareCheckResponse,
    value: EBTTriggerTypeValue.WelfareCheckResponse,
    disabled: true,
    options: [],
  },
];
