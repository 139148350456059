import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import {
  EBTListenerTypeId,
  FenceZone,
  FenceZoneValue,
  GeofenceEntityTypeId,
  GeofenceEntityTypeValue,
} from '../../../../util/enums';
import { getFenceTypeIcon } from '../../../Map/Helpers';
import { GeofenceType } from '../../types';
import { ALL, ALL_GEOFENCE_TYPES } from '../../../../util/constants';
import { GeofenceTypes } from '../../values';
import { EBTProps } from '../../Props';

const SelectAFenceType = (
  props: EBTProps & {
    isCrossing: boolean;
    isPolygonZone: boolean;
  },
) => {
  const disableLineSelction = (typeId: string) =>
    (!props.isCrossing && typeId === GeofenceEntityTypeId.Line) ||
    (props.selectedZone?.id === FenceZone.none && typeId === GeofenceEntityTypeId.Multipolygon);

  return (
    <>
      <FormControl
        style={{
          width: '100%',
          marginBottom: '15px',
        }}
      >
        <InputLabel id="fence-type-option">Geofence Type</InputLabel>
        <Select
          fullWidth
          labelId="fence-type-action"
          id="fence-type-dropdown"
          value={props.selectedGeofenceType.value}
          label="Geofence Type"
          disabled={
            props.isCrossing ||
            props.isPolygonZone ||
            (!!props.eventIds && props.selectedLocationListenerType === EBTListenerTypeId.Geofence)
          }
          style={{
            height: '60px',
          }}
          onChange={e => {
            const fenceType = GeofenceTypes.find(
              z => z.value === (e.target.value as GeofenceEntityTypeValue),
            );
            props.featurePageRef.current = 1;
            props.setSearchString(undefined);
            props.setSelectedLocationListenerType(EBTListenerTypeId.Layer);
            props.setAvailableFeatures([]);

            if (!fenceType) {
              props.setSelectedGeofenceType({ id: ALL, value: ALL_GEOFENCE_TYPES });
              return;
            }
            props.setSelectedGeofenceType(fenceType);
            if (fenceType.id === GeofenceEntityTypeId.Line) {
              props.setSelectedZone({ id: FenceZone.none, value: FenceZoneValue.NoZone });
            } else if (fenceType.id === GeofenceEntityTypeId.Multipolygon) {
              props.setSelectedZone({ id: FenceZone.cleared, value: FenceZoneValue.Cleared });
            }
          }}
        >
          {[{ id: ALL, value: ALL_GEOFENCE_TYPES }, ...GeofenceTypes].map(type => (
            <MenuItem key={type.id} value={type.value} disabled={disableLineSelction(type.id)}>
              <Grid
                container
                style={
                  type.id !== ALL
                    ? {
                        display: 'grid',
                        gap: '20px',
                        gridTemplateColumns: '30px 200px',
                      }
                    : {
                        marginLeft: '50px',
                      }
                }
              >
                {type.id !== ALL ? getFenceTypeIcon(type.id as GeofenceEntityTypeId) : ''}
                {type.value}
              </Grid>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default SelectAFenceType;
