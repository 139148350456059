import { DirectionsRun } from '@mui/icons-material';
import { Button, Container, Grid } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { Header } from '../../../Common/Sidebar';
import { EBTProps } from '../Props';
import TriggerType from './TriggerType';
import TriggerOption from './TriggerOption';
import TriggerValue from './TriggerValue';
import SelectAnEventNotification from './SelectAnEventNotification';
import EventNotificationForm from './EventNotificationForm';
import {
  EBTListenerTypeId,
  EBTNotifierTypeId,
  EBTTriggerTypeId,
  EBTTriggerTypeOptionsId,
  GeofenceEntityTypeId,
} from '../../../util/enums';
import TriggerName from './TriggerOptions/TriggerName';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { NOTIFIER_URL, TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import { validateEmailAddress } from '../Helpers';
import axios from 'axios';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { geometryTypeOfEntity } from '../../Map/commons';
import { GridRowData } from '@material-ui/data-grid';
import { NameId } from '../../Map/types';
import { ALL } from '../../../util/constants';

const EBTForm = (props: EBTProps) => {
  const notifierUrl = useAtomValue(NOTIFIER_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const hasAllTriggerValues = (): boolean => {
    const isLocationTriggerType = props.selectedTriggerType?.id === EBTTriggerTypeId.Location;
    const isProximityTriggerType = props.selectedTriggerType?.id === EBTTriggerTypeId.Proximity;
    const isSensorTriggerTypeWithValue =
      !!props.triggerValue && !isProximityTriggerType && !isLocationTriggerType;
    const isLocationLayerTriggerTypeWithValue =
      isLocationTriggerType &&
      props.selectedLocationListenerType === EBTListenerTypeId.Layer &&
      !!props.selectedLayer;
    const isLocationGeofenceTriggerTypeWithValue =
      isLocationTriggerType &&
      props.selectedLocationListenerType === EBTListenerTypeId.Geofence &&
      !!props.selectedFeature;
    const isProximityTriggerTypeWithValue = isProximityTriggerType && !!props.selectedFeature;
    return (
      isSensorTriggerTypeWithValue ||
      isLocationLayerTriggerTypeWithValue ||
      isLocationGeofenceTriggerTypeWithValue ||
      isProximityTriggerTypeWithValue
    );
  };

  const hasAllNotificationValues = (): boolean => {
    const isEmailNotification = props.selectedNotifierType?.id === EBTNotifierTypeId.Email;
    const isWebhhookNotification = props.selectedNotifierType?.id === EBTNotifierTypeId.Webhook;
    const isEmailNotificationWithValues = !!(
      isEmailNotification &&
      props.emailData?.address &&
      props.emailData?.subject &&
      props.emailData?.message
    );
    const isWebhookNotificationWithValues = !!(
      isWebhhookNotification &&
      props.webhookData?.url &&
      props.webhookData?.method
    );
    const hasNotificationValues = isEmailNotificationWithValues || isWebhookNotificationWithValues;
    const isLocationTriggerType = props.selectedTriggerType?.id === EBTTriggerTypeId.Location;
    const isProximityTriggerType = props.selectedTriggerType?.id === EBTTriggerTypeId.Proximity;
    const isSensorTriggerType =
      !isLocationTriggerType && !isProximityTriggerType && !!props.eventName;
    return (
      (isLocationTriggerType || isProximityTriggerType || isSensorTriggerType) &&
      hasNotificationValues
    );
  };

  const createListeners = useCallback(
    (id?: string) => {
      const isSensor =
        props.selectedTriggerType?.id === EBTTriggerTypeId.Battery ||
        props.selectedTriggerType?.id === EBTTriggerTypeId.Temperature ||
        props.selectedTriggerType?.id === EBTTriggerTypeId.Spo2;

      const listener = {
        type:
          props.selectedTriggerType?.id === EBTTriggerTypeId.Location
            ? props.selectedLocationListenerType
            : isSensor
            ? EBTListenerTypeId.Sensor
            : EBTListenerTypeId.Microfence,
        sensorType: isSensor ? 'boundary' : undefined,
        entered: isSensor
          ? true
          : props.selectedTriggerOption &&
            (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Entry ||
              props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Dwell ||
              props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.LeftToRightCrossing)
          ? true
          : false,
        dwellSeconds:
          props.selectedTriggerOption?.id !== EBTTriggerTypeOptionsId.Dwell
            ? undefined
            : isNaN(Number(props.triggerValue))
            ? 0
            : Number(props.triggerValue),
        id,
      };

      if (props.selectedTriggerType?.id === EBTTriggerTypeId.Location) {
        if (listener.type === EBTListenerTypeId.Geofence) {
          return [
            {
              ...listener,
              geofenceType: geometryTypeOfEntity(props.selectedFeature as GridRowData),
            },
          ];
        } else if (listener.type === EBTListenerTypeId.Layer) {
          return [
            {
              ...listener,
              layerOptions: {
                geofenceType:
                  props.selectedGeofenceType?.id === ALL ? '_all' : props.selectedGeofenceType?.id,
                geofenceZone: props.selectedZone?.id === ALL ? '_all' : props.selectedZone?.id,
              },
            },
          ];
        }
      }
      return [listener];
    },
    [props],
  );

  const createEmail = useCallback(
    async (id: string) => {
      await axios.post(
        `${notifierUrl}/${cid}/${pid}/email`,
        {
          text: props.emailData?.message,
          subject: props.emailData?.subject,
          recipients: [props.emailData?.address],
          listeners: createListeners(id),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, notifierUrl, pid, props],
  );

  const updateEmail = useCallback(
    async (emailId: string, triggerId: string) => {
      await axios.patch(
        `${notifierUrl}/${cid}/${pid}/email/${emailId}`,
        {
          text: props.emailData?.message,
          subject: props.emailData?.subject,
          recipients: [props.emailData?.address],
          listeners: createListeners(triggerId),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, notifierUrl, pid, props],
  );

  const createWebhook = useCallback(
    async (id: string) => {
      await axios.post(
        `${notifierUrl}/${cid}/${pid}/webhook`,
        {
          url: props.webhookData?.url,
          method: props.webhookData?.method,
          listeners: createListeners(id),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, notifierUrl, pid, props],
  );

  const updateWebhook = useCallback(
    async (webhookId: string, triggerId: string) => {
      await axios.patch(
        `${notifierUrl}/${cid}/${pid}/webhook/${webhookId}`,
        {
          url: props.webhookData?.url,
          method: props.webhookData?.method,
          listeners: createListeners(triggerId),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, notifierUrl, pid, props],
  );

  const addDwellTimeToFence = useCallback(
    async (layer: NameId | undefined, id: string, type: GeofenceEntityTypeId) => {
      if (!layer || !id || !type) return;
      const fence = (
        await axios.get<GridRowData>(
          `${triggersUrl}/${cid}/${pid}/geofences/${layer.id}/${type}/${id}`,
          authedConfig,
        )
      ).data;

      const geomobyProperties: Record<string, string> = fence.geomobyProperties;
      geomobyProperties['dwellSeconds'] = String(props.triggerValue);

      await axios.patch(
        `${triggersUrl}/${cid}/${pid}/geofences/${layer.id}/${type}/${id}`,
        {
          geometry: fence.points,
          properties: {
            ...fence,
            geomobyProperties,
          },
          type: 'Feature',
        },
        authedConfig,
      );
    },
    [authedConfig, cid, triggersUrl, pid, props],
  );

  const handleSubmit = useCallback(async () => {
    const invalidEmailAddress = props.emailData
      ? !validateEmailAddress(props.emailData?.address ?? '')
      : false;
    if (invalidEmailAddress) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: invalidEmailAddress
          ? 'Invalid email address.'
          : 'Please complete all the required fields.',
      });
      return;
    }

    try {
      if (
        props.selectedTriggerType?.id === EBTTriggerTypeId.Battery ||
        props.selectedTriggerType?.id === EBTTriggerTypeId.Temperature ||
        props.selectedTriggerType?.id === EBTTriggerTypeId.Spo2
      ) {
        const sensor = {
          sensorType:
            props.selectedTriggerType?.id === EBTTriggerTypeId.Temperature
              ? 'TEMPERATURE_CELSIUS'
              : props.selectedTriggerType?.id === EBTTriggerTypeId.Spo2
              ? 'SPO2_PERCENT'
              : props.selectedTriggerType?.id === EBTTriggerTypeId.Battery
              ? 'BATTERY_PERCENT'
              : undefined,
          name: props.eventName,
          boundary: Number(props.triggerValue),
          higherIsEnter: props.selectedTriggerOption?.id === 'ABOVE',
        };

        if (props.eventIds) {
          const { id } = (
            await axios.patch(
              `${triggersUrl}/${cid}/${pid}/boundary/${props.eventIds.listenerId}`,
              sensor,
              authedConfig,
            )
          ).data;
          if (props.eventIds.emailId) {
            await updateEmail(props.eventIds.emailId, id);
          } else if (props.eventIds.webhookId) {
            await updateWebhook(props.eventIds.webhookId, id);
          }
        } else {
          const { id } = (
            await axios.post(`${triggersUrl}/${cid}/${pid}/boundary`, sensor, authedConfig)
          ).data;
          if (props.selectedNotifierType?.id === EBTNotifierTypeId.Email) {
            await createEmail(id);
          } else if (props.selectedNotifierType?.id === EBTNotifierTypeId.Webhook) {
            await createWebhook(id);
          }
        }
      } else if (props.selectedFeature || props.selectedLayer) {
        const id = props.selectedFeature?.id ?? props.selectedLayer?.id;
        if (props.selectedNotifierType?.id === EBTNotifierTypeId.Email && props.emailData) {
          props.eventIds?.emailId
            ? await updateEmail(props.eventIds.emailId, id)
            : await createEmail(id);
        } else if (
          props.selectedNotifierType?.id === EBTNotifierTypeId.Webhook &&
          props.webhookData
        ) {
          props.eventIds?.webhookId
            ? await updateWebhook(props.eventIds.webhookId, id)
            : await createWebhook(id);
        }

        if (
          props.selectedFeature &&
          props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Dwell
        ) {
          await addDwellTimeToFence(
            props.selectedLayer,
            props.selectedFeature.id,
            props.selectedFeature.points.type,
          );
        }
      }

      props.clearForm();
      props.setRefreshEBTs(true);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (e) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message:
          (e as Error).message ??
          `'Failed ${
            props.eventIds ? 'updating' : 'creating'
          } ${props.selectedNotifierType?.id.toLowerCase()}.`,
      });
    }
  }, [
    addDwellTimeToFence,
    authedConfig,
    cid,
    createEmail,
    createWebhook,
    pid,
    setSaveNotification,
    triggersUrl,
    updateEmail,
    updateWebhook,
    props,
  ]);

  return (
    <>
      <Container>
        <Grid
          spacing={3}
          paddingY={2}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: '60px',
            paddingBottom: '10px',
          }}
        >
          <Header icon={<DirectionsRun />}>{props.eventIds ? 'Update' : 'Create new'} event</Header>

          {/* Battery, Location, Proximity... */}
          {TriggerType(props)}
          {/* Above, Below, Entry, Exit... */}
          {TriggerOption(props)}
          {/* Enter a battery value, select a geofence, select a microfence... */}
          {TriggerValue(props)}
          {/* Email or Webhook */}
          {SelectAnEventNotification({
            ...props,
            showSelectAnEventNotification: hasAllTriggerValues(),
          })}
          {/* Email details or Webhook details */}
          {EventNotificationForm({
            ...props,
            showEventNotificationForm: !!props.selectedNotifierType && hasAllTriggerValues(),
          })}
          {/* Name your event trigger */}
          {TriggerName(props)}

          {/* Create/Update and Cancel */}
          <Grid
            container
            direction="column"
            style={{
              width: 'calc(100% - 10vw)',
              marginTop: '20px',
              display: 'grid',
              gap: '2%',
              gridTemplateColumns: '49% 49%',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleSubmit}
              disabled={!(hasAllTriggerValues() && hasAllNotificationValues())}
            >
              {props.eventIds ? 'Update' : 'Create'}
            </Button>
            <Button variant="outlined" size="large" onClick={() => props.clearForm()}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default EBTForm;
