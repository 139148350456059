import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { BACKGROUND } from '../../../Style/GeoMobyBaseTheme';
import { EBTTriggerTypeId, EBTTriggerTypeOptionsId } from '../../../util/enums';
import InputContainer from '../../Global/InputContainer';
import { EBTProps } from '../Props';
import LocationTriggerOption from './TriggerOptions/LocationTriggerOption';
import SelectAMicrofence from './TriggerOptions/SelectAMicrofence';

const TriggerValue = (props: EBTProps) => {
  const [maxDate, setMaxDate] = useState<Date | null>();
  const [minDate, setMinDate] = useState<Date | null>();

  return (
    <>
      {props.selectedTriggerType && props.selectedTriggerOption && (
        <Paper
          variant="outlined"
          style={{
            width: 'calc(100% - 10vw)',
            background: BACKGROUND,
          }}
        >
          <Box padding={2}>
            <form
              className={
                props.selectedTriggerType.id === EBTTriggerTypeId.Proximity
                  ? 'hidden'
                  : 'trigger-form'
              }
              noValidate
              autoComplete="off"
              onChange={e => {
                if (
                  props.selectedTriggerType?.id === EBTTriggerTypeId.Proximity ||
                  (props.selectedTriggerType?.id === EBTTriggerTypeId.Location &&
                    props.selectedTriggerOption?.id !== EBTTriggerTypeOptionsId.Dwell)
                )
                  return;
                props.setTriggerValue((e.target as HTMLTextAreaElement).value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              {(() => {
                switch (props.selectedTriggerType?.id) {
                  case EBTTriggerTypeId.Proximity:
                    return (
                      <>
                        {SelectAMicrofence({
                          ...props,
                          showSelectAMicrofence:
                            props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Entry ||
                            props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Exit,
                        })}
                      </>
                    );
                  case EBTTriggerTypeId.Location:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Dwell) {
                      return (
                        <InputContainer
                          id="dwell-above"
                          label="Above dwell threshold (seconds)"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="60"
                          required
                        />
                      );
                    } else
                      return (
                        <>
                          {LocationTriggerOption({
                            ...props,
                            showLocationTriggerOption:
                              props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Entry ||
                              props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Exit ||
                              props.selectedTriggerOption?.id ===
                                EBTTriggerTypeOptionsId.LeftToRightCrossing ||
                              props.selectedTriggerOption?.id ===
                                EBTTriggerTypeOptionsId.RightToLeftCrossing,
                          })}
                        </>
                      );
                  case EBTTriggerTypeId.Temperature:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Below)
                      return (
                        <InputContainer
                          id="temperature-below"
                          label="Below body temperature threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="36.6"
                          required
                        />
                      );
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Above)
                      return (
                        <InputContainer
                          id="temperature-above"
                          label="Above body temperature threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="37.0"
                          required
                        />
                      );
                    break;
                  case EBTTriggerTypeId.Spo2:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Below)
                      return (
                        <InputContainer
                          id="spo2-below"
                          label="Below oxygenation threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="95"
                          required
                        />
                      );
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Above)
                      return (
                        <InputContainer
                          id="spo2-above"
                          label="Above oxygenation threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="97"
                          required
                        />
                      );
                    break;
                  case EBTTriggerTypeId.Battery:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Below)
                      return (
                        <InputContainer
                          id="spo2-below"
                          label="Below battery level threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="95"
                          required
                        />
                      );
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Above)
                      return (
                        <InputContainer
                          id="spo2-above"
                          label="Above battery level threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="97"
                          required
                        />
                      );
                    break;
                  case EBTTriggerTypeId.Scheduler:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.NewRule)
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="start-date-scheduler"
                            label="Start Date"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_start'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_start'
                            }
                            required
                            dateRange
                            // valueDate={String(minDate)} // TODO: SCHEDULER. Use date.toIsoString() instead
                            valueDate={minDate ? String(minDate) : undefined}
                            onChangeDate={setMinDate}
                          />
                          <InputContainer
                            id="end-date-scheduler"
                            label="End Date"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_end'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_end'
                            }
                            required
                            dateRange
                            // valueDate={String(maxDate)} // TODO: SCHEDULER. Use date.toIsoString() instead
                            valueDate={maxDate ? String(maxDate) : undefined}
                            onChangeDate={setMaxDate}
                          />
                        </Stack>
                      );
                    break;
                  case EBTTriggerTypeId.Webhook:
                    if (props.selectedTriggerOption.id === 'GET')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="webhook-trigger-get-url"
                            label="URL"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            required
                          />
                          <InputContainer
                            id="webhook-trigger-get-response-field"
                            label="Response field"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              defaultValue={false}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="webhook-trigger-get-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    if (props.selectedTriggerOption.id === 'POST')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="webhook-trigger-post-url"
                            label="URL"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            required
                          />
                          <InputContainer
                            id="webhook-trigger-post-response-field"
                            label="Response field"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              defaultValue={false}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="webhook-trigger-post-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    break;
                  case EBTTriggerTypeId.Custom:
                    if (props.selectedTriggerOption.id === 'STRING')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="custom-trigger-string-key"
                            label="Key"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              value={props.customIsEqual}
                              onChange={e => {
                                e.target.value === 'true'
                                  ? props.setCustomIsEqual(true)
                                  : props.setCustomIsEqual(false);
                              }}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="custom-trigger-string-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    if (props.selectedTriggerOption.id === 'NUMBER')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="custom-trigger-number-key"
                            label="Key"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              value={props.customIsEqual}
                              onChange={e => {
                                e.target.value === 'true'
                                  ? props.setCustomIsEqual(true)
                                  : props.setCustomIsEqual(false);
                              }}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="custom-trigger-number-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    break;
                  default:
                }
              })()}
            </form>
          </Box>
        </Paper>
      )}
    </>
  );
};
export default TriggerValue;
