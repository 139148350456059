import { Box, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { BACKGROUND } from '../../../../Style/GeoMobyBaseTheme';
import { EBTTriggerTypeId } from '../../../../util/enums';
import { EBTProps } from '../../Props';

const TriggerName = (props: EBTProps) => {
  return (
    <>
      {props.selectedNotifierType &&
        !(
          props.selectedTriggerType?.id === EBTTriggerTypeId.Location ||
          props.selectedTriggerType?.id === EBTTriggerTypeId.Proximity
        ) && (
          <Paper
            style={{
              width: 'calc(100% - 10vw)',
              background: BACKGROUND,
            }}
          >
            <Box p={2} overflow="auto">
              <Stack direction="column" spacing={2}>
                <Typography variant="body1">
                  When the
                  <Typography color="primary" component="span" fontWeight="bold">
                    {' ' + props.selectedTriggerType?.id} - {props.selectedTriggerOption?.id}
                  </Typography>{' '}
                  event occurs,
                </Typography>
                <Typography>
                  a corresponding
                  <Typography color="primary" component="span" fontWeight="bold">
                    {' '}
                    {' ' + props.selectedNotifierType?.id}
                  </Typography>{' '}
                  will be fired.
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Tooltip title={props.eventName}>
                    <TextField
                      onChange={e => props.setEventName(e.target.value)}
                      name="event-name"
                      value={props.eventName ?? ''}
                      placeholder="Name your new event trigger"
                      sx={{
                        '& input': {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      autoComplete="title"
                      fullWidth
                      autoFocus
                    ></TextField>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
          </Paper>
        )}
    </>
  );
};
export default TriggerName;
