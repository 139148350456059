import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { ALL, ALL_GEOFENCE_TYPES, ALL_GEOFENCE_ZONES } from '../../../util/constants';
import {
  EBTListenerTypeId,
  EBTTriggerTypeId,
  EBTTriggerTypeOptionsId,
  EBTTriggerTypeOptionsValue,
  FenceZone,
  FenceZoneValue,
  GeofenceEntityTypeId,
  GeofenceEntityTypeValue,
} from '../../../util/enums';
import { getTriggerOptionIcon } from '../Helpers';
import { EBTProps } from '../Props';

const TriggerOption = (props: EBTProps) => {
  return (
    <>
      {props.selectedTriggerType && (
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            width: 'calc(100% - 10vw)',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="trigger-option">Event Trigger Option</InputLabel>
          <Select
            fullWidth
            labelId="trigger-option"
            id="trigger-option-dropdown"
            value={props.selectedTriggerOption?.value ?? ''}
            label="Event Trigger Option"
            onChange={e => {
              const triggerOption = props.selectedTriggerType?.options.find(
                option => option.value === (e.target.value as EBTTriggerTypeOptionsValue),
              );
              props.setSelectedTriggerOption(triggerOption);
              if (props.selectedTriggerType?.id === EBTTriggerTypeId.Location) {
                if (
                  triggerOption?.id === EBTTriggerTypeOptionsId.Entry ||
                  triggerOption?.id === EBTTriggerTypeOptionsId.Exit ||
                  triggerOption?.id === EBTTriggerTypeOptionsId.Dwell
                ) {
                  props.setSelectedZone({ id: ALL, value: ALL_GEOFENCE_ZONES });
                  props.setSelectedGeofenceType({ id: ALL, value: ALL_GEOFENCE_TYPES });
                } else if (
                  triggerOption?.id === EBTTriggerTypeOptionsId.LeftToRightCrossing ||
                  triggerOption?.id === EBTTriggerTypeOptionsId.RightToLeftCrossing
                ) {
                  props.setSelectedZone({ id: FenceZone.none, value: FenceZoneValue.NoZone });
                  props.setSelectedGeofenceType({
                    id: GeofenceEntityTypeId.Line,
                    value: GeofenceEntityTypeValue.Tripwire,
                  });
                }
                props.setSelectedLocationListenerType(EBTListenerTypeId.Layer);
                props.setSelectedLayer(undefined);
                props.setAvailableFeatures([]);
                props.setTriggerValue(undefined);
              }
              props.setIsEditing(false);
            }}
          >
            {props.selectedTriggerType.options.map(option => (
              <MenuItem key={option.id} value={option.value} disabled={option.disabled}>
                <Grid
                  container
                  style={{
                    display: 'grid',
                    gap: '20px',
                    gridTemplateColumns: '30px 200px',
                  }}
                >
                  {props.selectedTriggerType
                    ? getTriggerOptionIcon(props.selectedTriggerType.id, option.id)
                    : undefined}
                  {option.value}
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default TriggerOption;
