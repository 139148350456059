import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Box, Fab, Grid, Tooltip, Typography } from '@mui/material';
import {
  RadioButtonUnchecked,
  CheckBoxOutlineBlank,
  DashboardOutlined,
  ShowChart,
  BluetoothAudio,
  Router,
  PanTool,
  CropRotate,
  Edit,
  Straighten,
  Smartphone,
} from '@mui/icons-material';
import VectorLayer from 'ol/layer/Vector';
import { MICROFENCE_LAYER_ID } from '../BeaconUtils';
import { createPenSubTools, MEASUREMENT_TOOLS } from './createPenSubTools';
import { DrawType, EditType, FenceGeometryType } from '../../../util/enums';
import { Map as olMap } from 'ol';

type Tools = [JSX.Element, DrawType | undefined, EditType | undefined, string][];

const OUTDOOR_TOOLS: Tools = [
  [
    <RadioButtonUnchecked key={DrawType.Circle} />,
    DrawType.Circle,
    undefined,
    `Draw circular GeoFence`,
  ],
  [
    <CheckBoxOutlineBlank key={DrawType.Polygon} />,
    DrawType.Polygon,
    undefined,
    `Draw polygonal GeoFence`,
  ],
  [
    <DashboardOutlined key={DrawType.Multipolygon} />,
    DrawType.Multipolygon,
    undefined,
    `Draw multipolygonal GeoFence`,
  ],
  [<ShowChart key={DrawType.Tripwire} />, DrawType.Tripwire, undefined, `Draw tripwire GeoFence`],
  [<Straighten key={DrawType.Measure} />, DrawType.Measure, undefined, `Measure Distance`],
  [<Edit key={EditType.ShapeChange} />, undefined, EditType.ShapeChange, `Edit`],
  [<CropRotate key={EditType.ScaleRot} />, undefined, EditType.ScaleRot, `Scale and Rotate`],
];

const INDOOR_TOOLS: Tools = [
  [
    <Router key={DrawType.MicrofenceGateway} />,
    DrawType.MicrofenceGateway,
    undefined,
    `Place Gateway MicroFence`,
  ],
  [
    <BluetoothAudio key={DrawType.MicrofenceBeacon} />,
    DrawType.MicrofenceBeacon,
    undefined,
    `Place Beacon MicroFence`,
  ],
  [
    <Smartphone key={DrawType.MicrofenceDevice} />,
    DrawType.MicrofenceDevice,
    undefined,
    `Place Device MicroFence`,
  ],
];

export const createPenTools = (props: {
  olmap: olMap;
  mapType: 'INDOOR' | 'OUTDOOR';
  selectedLayer: string | undefined;
  geofenceType: FenceGeometryType | undefined;
  geofenceTooBig: boolean;
  editing: boolean;
  isLoading: boolean;
  setEditing: (olmap: olMap) => void;
  unsetEditing: (olmap: olMap) => void;
  drawType: DrawType | undefined;
  setDrawType: Dispatch<SetStateAction<DrawType | undefined>>;
  editType: EditType | undefined;
  setEditType: Dispatch<SetStateAction<EditType | undefined>>;
}): ReactNode[] => {
  const isMicrofenceLayer = props.selectedLayer === MICROFENCE_LAYER_ID;
  const tools = props.mapType === 'INDOOR' || isMicrofenceLayer ? INDOOR_TOOLS : OUTDOOR_TOOLS;

  return [
    <Typography variant="caption" key="tools">
      Tools
    </Typography>,
    <Grid item key="none">
      <Fab
        color={!props.drawType ? 'secondary' : undefined}
        size="small"
        onClick={() => {
          props.setDrawType(undefined);
          if (props.editing) {
            props.unsetEditing(props.olmap);
          }
        }}
      >
        <PanTool fontSize="small" />
      </Fab>
    </Grid>,
    ...tools.map(([icon, drawType, editType, tooltip], index) => {
      const disabledDrawType = !drawType || (drawType && !props.selectedLayer) || props.isLoading;
      const disabledEditType =
        props.drawType === DrawType.Measure ||
        !editType ||
        (editType && !props.selectedLayer) ||
        props.isLoading ||
        !props.drawType;

      const isDisableToolsWhenPolygon =
        props.geofenceType === FenceGeometryType.Multipolygon &&
        (drawType === DrawType.Circle ||
          drawType === DrawType.Polygon ||
          drawType === DrawType.Tripwire);
      const isDisableToolsWhenMultipolygon =
        (props.geofenceType === FenceGeometryType.Polygon ||
          props.geofenceType === FenceGeometryType.Line) &&
        drawType === DrawType.Multipolygon;
      const isDisabled =
        Boolean(drawType ? disabledDrawType : disabledEditType) ||
        isDisableToolsWhenPolygon ||
        isDisableToolsWhenMultipolygon;

      const selectedDrawType = drawType && props.drawType === drawType;
      const selectedEditType = editType && props.editType === editType;
      return (
        <Grid item key={index}>
          <Tooltip title={isDisabled ? '' : tooltip} arrow>
            <Fab
              color={
                drawType && selectedDrawType
                  ? 'secondary'
                  : !drawType && selectedEditType
                  ? 'primary'
                  : undefined
              }
              size="small"
              onClick={() => {
                if (editType) {
                  props.setEditType(editType);
                  if (!props.editing) {
                    props.setEditing(props.olmap);
                  }
                } else if (drawType && !editType) {
                  props.setEditType(
                    drawType === DrawType.Measure ? undefined : EditType.ShapeChange,
                  );
                  props.setDrawType(drawType);
                  if (!props.editing) {
                    props.setEditing(props.olmap);
                  }
                } else if (!drawType && props.editing) {
                  props.unsetEditing(props.olmap);
                }
              }}
              disabled={isDisabled || (props.geofenceTooBig && editType === EditType.ScaleRot)}
            >
              {icon}
            </Fab>
          </Tooltip>
        </Grid>
      );
    }),
  ];
};
