import {
  Build,
  CheckBoxOutlineBlank,
  DashboardOutlined,
  Shield,
  ShieldOutlined,
  ShowChart,
} from '@mui/icons-material';
import { Grid, Tooltip, Typography } from '@mui/material';
import { transform } from 'ol/proj';
import { Geometry, LineString, Point } from 'ol/geom';
import { EntityType, FenceZone, GeofenceEntityTypeId, MicrofenceEntity } from '../../util/enums';
import { jsUcFirst } from '../Global/StringFormatterFunctions';
import { SearchListProps } from './LiveAndReplay/Props';
import { PortableAssetTool, SelectedAsset } from './types';
import { Feature, Map as OlMap } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import {
  ACTIVE_GREEN,
  CLEARED_ZONE,
  PRIMARY,
  SECONDARY,
  truncationStyle,
} from '../../Style/GeoMobyBaseTheme';
import { AssetState, SensedByDevice } from './Messages';
import { GridRowData } from '@material-ui/data-grid';
import { uniqBy } from 'lodash';
import { isLocalisedAssetId, unlocaliseAssetId } from './LiveAndReplay/MapUpdates';
import { MICROFENCE_LAYER_ID } from './BeaconUtils';

export const findKnownTool = (
  identifiers: Record<string, unknown> | undefined,
  knownTools: PortableAssetTool[],
): PortableAssetTool | undefined => {
  if (!identifiers) return;
  return knownTools.find(tool => tool.type === 'tool' && String(tool.id) === identifiers?.beaconId);
};

export const displayAssetPrefixType = (asset: SelectedAsset, knownTools: PortableAssetTool[]) => {
  return (
    <>
      {asset.id?.deviceId && `[${EntityType.Device}] `}
      {asset.id?.beaconId && !findKnownTool(asset.id, knownTools) && `[${EntityType.Beacon}] `}
      {asset.id?.beaconId && findKnownTool(asset.id, knownTools) && (
        <Build
          style={{
            color: 'lightslategray',
            marginRight: '5px',
            marginBottom: '-7px',
          }}
        />
      )}
      {asset.id?.gpsTrackerId && `[${EntityType.Tracker}] `}
    </>
  );
};

export const displayAssetHeading = (label: string | undefined, id: string, type: EntityType) => {
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        marginLeft={0}
        justifyContent="left"
        alignItems="center"
      >
        <Tooltip title={label || id} style={truncationStyle}>
          <Typography variant="h5">
            {(label || id) && <span style={truncationStyle}>{label || id}</span>}
            {!(label || id) && (
              <span
                style={{
                  color: SECONDARY,
                }}
              >
                {`${type} IS UNKNOWN`}
              </span>
            )}
          </Typography>
        </Tooltip>
      </Grid>
    </>
  );
};

export const displayAssetLabel = (label: string | undefined, id: string) => {
  return (
    <>
      <Tooltip title={label || (id ? id : 'UNKNOWN ID')}>
        <Typography
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label && <span style={truncationStyle}>{label}</span>}
          {!label && (
            <span>
              <span
                style={{
                  color: SECONDARY,
                }}
              >
                NO LABEL
              </span>
              <span style={truncationStyle}>{` (${id ? id : 'UNKNOWN ID'})`}</span>
            </span>
          )}
        </Typography>
      </Tooltip>
    </>
  );
};

export const displayAssetId = (id: string, type: EntityType) => {
  return (
    <>
      <Grid container direction={'row'}>
        <Tooltip title={`${jsUcFirst(type)} ID:`}>
          <Typography
            style={{
              color: PRIMARY,
              paddingRight: '5px',
              width: 'fit-content',
            }}
          >
            {`${jsUcFirst(type)} ID:`}
          </Typography>
        </Tooltip>

        <Tooltip title={id ? id : 'UNKNOWN ID'}>
          <Typography
            style={{
              width: 'fit-content',
            }}
          >
            {id ? id : 'UNKNOWN ID'}
          </Typography>
        </Tooltip>
      </Grid>
    </>
  );
};

export const displayAssetIsActive = () => {
  return (
    <>
      <Typography
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <span
          style={{
            marginLeft: '10px',
            color: ACTIVE_GREEN,
            ...truncationStyle,
          }}
        >
          ACTIVE
        </span>
      </Typography>
    </>
  );
};

export const displayAssetActivityStatus = (isActive: boolean) => {
  return (
    <>
      <Grid item container direction={'row'} xs={12} fontSize="90%">
        <Tooltip title={'Status'}>
          <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Status:</Typography>
        </Tooltip>
        <Tooltip title={isActive ? 'ACTIVE' : 'NOT CURRENTLY ACTIVE'}>
          <Typography style={{ color: isActive ? ACTIVE_GREEN : SECONDARY }}>
            {isActive ? 'ACTIVE' : 'NOT CURRENTLY ACTIVE'}
          </Typography>
        </Tooltip>
      </Grid>
    </>
  );
};

export const distanceBetweenGpsCoords = (a: [number, number], b: [number, number]) =>
  new LineString([a, b].map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857'))).getLength();

export const deselectFences = (olmap: OlMap | undefined, exceptFenceId?: string) => {
  if (!olmap) return;
  olmap
    .getAllLayers()
    .filter(l => l instanceof VectorLayer)
    .forEach(l => {
      l.getSource()
        .getFeatures()
        .filter((f: Feature<Geometry>) => f.get('selected'))
        .forEach((f: Feature<Geometry>) => {
          if (f.get('id') === exceptFenceId && !!exceptFenceId) return;
          f.set('selected', false);
        });
    });

  olmap
    ?.getAllLayers()
    ?.find(layer => layer instanceof VectorLayer && layer.get('id') === MICROFENCE_LAYER_ID)
    ?.getSource()
    ?.getFeatures()
    ?.filter((f: Feature<Geometry>) => f.get('selected'))
    ?.forEach((f: Feature<Geometry>) => {
      if (f.get('id') === exceptFenceId && !!exceptFenceId) return;
      f.set('selected', false);
    });
};

export const findActiveAsset = (
  id: string,
  activeAssets: SelectedAsset[],
): SelectedAsset | undefined => {
  return activeAssets.find(
    active => active.id?.beaconId !== undefined && active.id?.beaconId === id,
  );
};

export const getZoneIcon = (zone: FenceZone) => {
  return (
    <>
      {zone === FenceZone.breach && <Shield style={{ color: 'red' }} />}
      {zone === FenceZone.buffer && <ShieldOutlined style={{ color: 'orange' }} />}
      {zone === FenceZone.cleared && <DashboardOutlined style={{ color: CLEARED_ZONE }} />}
    </>
  );
};

export const getFenceTypeIcon = (type: GeofenceEntityTypeId) => {
  return (
    <>
      {type === GeofenceEntityTypeId.Line && <ShowChart />}
      {type === GeofenceEntityTypeId.Multipolygon && <DashboardOutlined />}
      {type === GeofenceEntityTypeId.Polygon && <CheckBoxOutlineBlank />}
    </>
  );
};

// This function will still require more work in a subsequent tech debt ticket (https://geomoby.atlassian.net/browse/LTP-1192). I've just neatened it up for now.
export const getLocalisedAssets = (
  selectedAsset: SelectedAsset | undefined,
  assets: { assetId: string; assetState: AssetState }[],
  selectedGeofence: GridRowData | undefined,
): AssetState[] => {
  if (selectedGeofence) {
    return assets
      .filter(asset => asset.assetState.lastFenceEvent?.fenceId === selectedGeofence.id)
      ?.map(asset => asset.assetState);
  }
  if (selectedAsset?.label === undefined) return [];

  if (!isLocalisedAssetId(selectedAsset.label)) {
    return [
      assets.filter(
        asset => JSON.stringify(asset.assetState.id) === JSON.stringify(selectedAsset.id),
      )?.[0]?.assetState,
    ];
  }

  const relatedAsset = assets.find(
    asset => selectedAsset.label && asset.assetId === unlocaliseAssetId(selectedAsset.label),
  );
  if (!relatedAsset) return [];

  const sensed = relatedAsset.assetState.lastSensed?.assets || [];
  const latestLocation = assets
    .filter(asset => sensed?.find(s => s.label === asset.assetState.label))
    .sort(
      (a, b) =>
        new Date(b.assetState.lastLocation?.timestamp || 0).getTime() -
        new Date(a.assetState.lastLocation?.timestamp || 0).getTime(),
    )
    .at(0)?.assetState.lastLocation;

  const lastLocations = Array.from(assets.values())
    .filter(a => a.assetState.lastLocation)
    .map(a => a.assetState.lastLocation)
    .filter(a => a && JSON.stringify(a.source?.id) === JSON.stringify(relatedAsset.assetState.id));

  if (latestLocation) {
    lastLocations.push(latestLocation);
  }

  const localisedAsset: AssetState | undefined = {
    id: { ...selectedAsset.id, localised: 'true' },
    label: selectedAsset.label,
    lastSensed: relatedAsset.assetState.lastSensed,
    lastLocalBeacons: relatedAsset.assetState.lastLocalBeacons,
    lastLocation: latestLocation,
    lastFenceEvent: undefined,
    lastTemp: undefined,
    lastTempRangeEvent: undefined,
    lastWelfareCheckResponse: undefined,
    recentSensedTriggered: undefined,
  };
  return [localisedAsset];
};

export const getMicroFenceIcon = (type: MicrofenceEntity) => {
  return (
    <>
      {type === MicrofenceEntity.Beacon && (
        <img
          style={{ margin: '-12px 8px -12px 0px' }}
          src="./IndoorMap/BeaconFar.svg"
          alt="Beacon Microfence"
        />
      )}
      {type === MicrofenceEntity.Device && (
        <img
          style={{ margin: '-16px -4px -28px -2px' }}
          src="./IndoorMap/SmartphoneFar.svg"
          alt="Device Microfence"
        />
      )}
      {(type === MicrofenceEntity.Gateway || type === MicrofenceEntity.Smartplug) && (
        <img
          style={{ margin: '-20px -66px -25px 0px' }}
          src="./IndoorMap/RouterFar.svg"
          alt="Gateway Microfence"
        />
      )}
    </>
  );
};

export const getSearchSelectOptions = (
  assets: { assetId: string; assetState: AssetState }[],
): SelectedAsset[] => {
  const as = uniqBy(
    [
      ...assets.map(asset => {
        return {
          label: asset.assetState.label,
          id: asset.assetState.id,
        };
      }),
      ...assets
        .map(asset => asset.assetState.lastSensed)
        .flatMap(asset => asset?.assets ?? [])
        .map(asset => {
          return {
            label: asset.label,
            id: { beaconId: asset.beaconId },
          };
        }),
    ],
    a => JSON.stringify(a.id),
  );
  return as.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
};

export const getSensedBeacon = (
  selectedAsset: SelectedAsset | undefined,
  assetStates: AssetState[],
): SensedByDevice | undefined => {
  if (!selectedAsset) return;

  const lastLocation = assetStates
    ?.filter(as => as?.lastLocation)
    .find(as => as.lastLocation?.beaconId === selectedAsset.id?.beaconId)?.lastLocation;
  if (lastLocation) {
    return {
      beaconId: lastLocation.beaconId,
      source: lastLocation.source,
      timestamp: lastLocation.timestamp,
    };
  }

  const lastSensedBeacons: SensedByDevice[] = [];
  assetStates
    .map(assetState => assetState.lastSensed)
    .forEach(sensed => {
      if (!sensed) return;
      sensed.assets?.map(beacon => {
        lastSensedBeacons.push({
          beaconId: beacon?.beaconId,
          source: { label: sensed.assetId, id: sensed.id },
          timestamp: sensed.timestamp,
        } as SensedByDevice);
      });
    });

  return lastSensedBeacons?.find(sensed => sensed.beaconId === selectedAsset?.id?.beaconId);
};

export const minutesDifferent = (a: Date, b: Date) =>
  Math.floor(a.getTime() / 1000 / 60) !== Math.floor(b.getTime() / 1000 / 60);

export const selectedElement = (props: SearchListProps): boolean =>
  !!props.selectedBeacon ||
  !!props.selectedDevice ||
  !!props.selectedGPSTracker ||
  !!props.selectedGeofence ||
  !!props.selectedMicrofence ||
  !!props.selectedTool;

export const dropPin = (coords: number[], address?: string): Feature<Point> => {
  const feature = new Feature(new Point(coords));
  const displayedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');

  feature.set(
    'searchedCoordinates',
    parseFloat(displayedCoords[1].toFixed(5)) + ', ' + parseFloat(displayedCoords[0].toFixed(5)),
  );
  feature.set('searchedAddress', address);
  return feature;
};
