import { GridRowData } from '@material-ui/data-grid';
import { Clear, Delete, DirectionsRun, Edit, FilterList } from '@mui/icons-material';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Header } from '../../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { NOTIFIER_URL, TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import {
  EBTNotifierTypeId,
  EBTListenerTypeId,
  EnteredTypeValue,
  GeofenceEntityTypeId,
  EBTNotifierTypeValue,
} from '../../../util/enums';
import { EBTFilterDialog } from '../EBTFilterDialog';
import { EBTProps } from '../Props';
import { EBT, EBTFilter, EventIds, Geofence } from '../types';
import { useAllBoundaries } from '../useAllBoundaries';
import { NameId } from '../../Map/types';
import EmailList from '../List/EmailList';
import WebhookList from '../List/WebhookList';
import { getEventTitle, getTypeTitle } from '../Helpers';
import { GeofenceTypes, TriggerTypes } from '../values';
import { geometryTypeOfEntity } from '../../Map/commons';
import { FenceZoneTypes } from '../../Map/values';
import { ALL, ALL_GEOFENCE_TYPES, ALL_GEOFENCE_ZONES } from '../../../util/constants';
import { keyBy } from 'lodash';

const EBTList = (props: EBTProps) => {
  const notifierUrl = useAtomValue(NOTIFIER_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const { execute: refreshBoundaries, data: boundaries } = useAllBoundaries();

  const [ebtCount, setEbtCount] = useState<number>(0);
  const [dwellTimeMinimum, setDwellTimeMinimum] = useState<number | undefined>();
  const [ebtFilter, setEbtFilter] = useState<EBTFilter | undefined>();
  const [enteredState, setEnteredState] = useState<EnteredTypeValue>();
  const [deleting, setDeleting] = useState<{ id: string; type: EBTNotifierTypeId } | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [geofences, setGeofences] = useState<Geofence[]>([]);
  const [microfences, setMicrofences] = useState<NameId[]>([]);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [startScrolling, setStartScrolling] = useState<boolean>(false);

  const boundariesById = useMemo(() => keyBy(boundaries, 'id'), [boundaries]);
  const geofencesById = useMemo(() => keyBy(geofences, 'id'), [geofences]);
  const layersById = useMemo(() => keyBy(props.availableLayers, 'id'), [props.availableLayers]);
  const microfencesById = useMemo(() => keyBy(microfences, 'id'), [microfences]);

  const ebtPageRef = useRef<number>(1);
  const isPaginatingRef = useRef<boolean>(false);

  const paginateEBTs = useCallback(async () => {
    let filters = `?perPage=200`;
    // TODO: Work-around for listenerType. Just until https://geomoby.atlassian.net/browse/LTP-1224 has been done.
    Object.entries(ebtFilter ?? {}).map(([key, value]) => {
      if (key !== undefined && value !== undefined) {
        filters = filters.concat(
          `&${key}=${
            typeof value === 'object'
              ? key === 'listenerType' && value.id === EBTListenerTypeId.Geofence
                ? `${EBTListenerTypeId.Geofence},${EBTListenerTypeId.Layer}`
                : value.id
              : value.toString()
          }`,
        );
      }
    });

    const { searches, count } = (
      await axios.get<{ searches: EBT[]; count: number }>(
        `${notifierUrl}/${cid}/${pid}/search/paginate/${ebtPageRef.current}${filters}`,
        authedConfig,
      )
    ).data;
    props.setAvailableEBTs(searches);
    setEbtCount(count);
    setDeleting(undefined);
    return searches;
  }, [notifierUrl, authedConfig, cid, pid, props, ebtFilter]);

  const getSpecifiedGeofences = useCallback(
    async (ebts: EBT[]) => {
      const geofenceIds = ebts
        .filter(e => e.listenerType === EBTListenerTypeId.Geofence)
        .map(e => e.listenerId)
        .toString();

      if (!geofenceIds) return;
      setIsLoading(true);
      const { data: fences } = await axios.get<Geofence[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/specified/${geofenceIds}`,
        authedConfig,
      );

      setGeofences(fences);
      setIsLoading(false);
    },
    [triggersUrl, cid, pid, authedConfig, setIsLoading],
  );

  const getSpecifiedMicrofences = useCallback(
    async (ebts: EBT[]) => {
      const microfenceIds = ebts
        .filter(e => e.listenerType === EBTListenerTypeId.Microfence)
        .map(e => e.listenerId)
        .toString();

      if (!microfenceIds) return;
      setIsLoading(true);
      const { data: fences } = await axios.get<{ id: string; name: string }[]>(
        `${triggersUrl}/${cid}/${pid}/microfences/specified/${microfenceIds}`,
        authedConfig,
      );

      setMicrofences(fences);
      setIsLoading(false);
    },
    [triggersUrl, cid, pid, authedConfig, setIsLoading],
  );

  const refreshAllEBTdata = useCallback(async () => {
    setIsLoading(true);
    ebtPageRef.current = 1;
    const ebts = await paginateEBTs();
    await refreshBoundaries();
    await getSpecifiedGeofences(ebts);
    await getSpecifiedMicrofences(ebts);
    setIsLoading(false);
  }, [paginateEBTs, refreshBoundaries, getSpecifiedGeofences, getSpecifiedMicrofences]);

  useEffect(() => {
    if (!props.refreshEBTs) return;
    refreshAllEBTdata();
    props.setRefreshEBTs(false);
  }, [props, refreshAllEBTdata]);

  const removeDwellTimeFromFence = async (
    layerId: string,
    id: string,
    type: GeofenceEntityTypeId,
  ) => {
    const fence = (
      await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
        authedConfig,
      )
    ).data;

    const geomobyProperties: Record<string, string> = fence.geomobyProperties;
    delete geomobyProperties['dwellSeconds'];

    await axios.patch(
      `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
      {
        geometry: fence.points,
        properties: {
          ...fence,
          geomobyProperties,
        },
        type: 'Feature',
      },
      authedConfig,
    );
  };

  const deleteAction = async (ebt: EBT, actionType: EBTNotifierTypeId, actionId: string) => {
    try {
      if (ebt.listenerType === EBTListenerTypeId.Sensor) {
        await axios.delete(`${triggersUrl}/${cid}/${pid}/boundary/${ebt.listenerId}`, authedConfig);
      }
      await axios.delete(
        `${notifierUrl}/${cid}/${pid}/${actionType.toLowerCase()}/${actionId}`,
        authedConfig,
      );
      await refreshAllEBTdata();
      if (ebt.dwellSeconds !== undefined) {
        const foundFence = geofences.find(f => f.id === ebt.listenerId);
        if (!foundFence) return;
        const { layerId, id, type } = foundFence;
        await removeDwellTimeFromFence(layerId, id, type);
      }
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      setSaveNotification({ id: SaveResult.FAIL, action: 'Delete' });
    }
  };

  const editEBT = async (ebt: EBT, notifierType: EBTNotifierTypeId) => {
    window.document.body.scrollTop = document.documentElement.scrollTop = 0;
    props.clearForm();
    props.setIsEditing(true);
    const foundBoundary = boundariesById[ebt.listenerId];
    const foundGeofence = geofencesById[ebt.listenerId];
    const foundLayer = layersById[ebt.listenerId];
    const foundMicrofence = microfencesById[ebt.listenerId];

    // Currently know way of singling out the correct page that the fence is associated with. I've created a ticket LTP-725.
    // Until then, I will search by the previously selected feature or layer. That way, most of the time it will be on page 1.
    if (notifierType === EBTNotifierTypeId.Email) {
      props.setEventIds({
        emailId: ebt.id,
        listenerId: ebt.listenerId,
      });
    } else {
      props.setEventIds({
        webhookId: ebt.id,
        listenerId: ebt.listenerId,
      });
    }

    const selectedTriggerValue = getTypeTitle(
      ebt.listenerType,
      boundariesById[ebt.listenerId]?.type,
      !!geofencesById[ebt.listenerId],
      !!layersById[ebt.listenerId],
      !!microfencesById[ebt.listenerId],
    );
    const selectedTriggerType = TriggerTypes.find(t => selectedTriggerValue === t.value);
    props.setSelectedTriggerType(selectedTriggerType);

    const selectedTriggerOptionValue = getEventTitle(
      ebt,
      boundariesById[ebt.listenerId]?.higherIsEnter,
      geofencesById[ebt.listenerId]?.type,
    );
    props.setSelectedTriggerOption(
      selectedTriggerType?.options.find(o => selectedTriggerOptionValue === o.value),
    );

    if (ebt.listenerType === EBTListenerTypeId.Geofence && ebt.dwellSeconds) {
      props.setTriggerValue(String(ebt.dwellSeconds));
    }
    if (
      ebt.listenerType !== EBTListenerTypeId.Geofence &&
      ebt.listenerType !== EBTListenerTypeId.Microfence
    ) {
      props.setEventName(foundBoundary?.name);
      props.setTriggerValue(String(foundBoundary?.boundary));
    }

    if (foundGeofence) {
      const { data: geofence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${foundGeofence.layerId}/${foundGeofence.type}/${foundGeofence.id}`,
        authedConfig,
      );
      props.setSelectedLayer(
        foundLayer ?? props.availableLayers.find(layer => layer.id === foundGeofence?.layerId),
      );
      props.setSelectedFeature(geofence);
      props.setSelectedLocationListenerType(EBTListenerTypeId.Geofence);
      props.setSearchString(foundGeofence.name);
      const fenceType = geometryTypeOfEntity(geofence as GridRowData);
      props.paginateGeofences({
        layerId: foundGeofence.layerId,
        searchName: foundGeofence.name,
        fenceType: fenceType ? [fenceType as unknown as GeofenceEntityTypeId] : undefined,
      });
    } else if (foundMicrofence) {
      const { data: microfence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/microfences/${foundMicrofence.id}`,
        authedConfig,
      );
      props.setAvailableMicrofencesCount(
        props.availableMicrofences.filter(microfence =>
          microfence.name?.toLowerCase().includes(microfence.name?.toLowerCase()),
        ).length,
      );
      props.setSearchString(microfence.name);
      props.setSelectedFeature(microfence);
    } else if (foundLayer) {
      props.setSelectedLayer(foundLayer);
      props.setSelectedLocationListenerType(EBTListenerTypeId.Layer);
      props.setSelectedGeofenceType(
        GeofenceTypes.find(geofenceType => geofenceType.id === ebt.layerOptions?.geofenceType) ?? {
          id: ALL,
          value: ALL_GEOFENCE_TYPES,
        },
      );
      props.setSelectedZone(
        FenceZoneTypes.find(geofenceZone => geofenceZone.id === ebt.layerOptions?.geofenceZone) ?? {
          id: ALL,
          value: ALL_GEOFENCE_ZONES,
        },
      );
    }

    if (notifierType === EBTNotifierTypeId.Email) {
      props.setSelectedNotifierType({
        id: EBTNotifierTypeId.Email,
        value: EBTNotifierTypeValue.Email,
      });
      props.setEmailData({
        message: ebt.text,
        subject: ebt.subject,
        address: ebt.recipients[0],
      });
    } else {
      props.setSelectedNotifierType({
        id: EBTNotifierTypeId.Webhook,
        value: EBTNotifierTypeValue.Webhook,
      });
      props.setWebhookData({
        method: ebt.method,
        url: ebt.url,
      });
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: '20px',
          marginRight: '26px',
        }}
      >
        <Header icon={<DirectionsRun />}>Active events</Header>
      </div>

      <Grid
        container
        direction="column"
        style={{
          marginLeft: '40px',
          display: 'grid',
          gridTemplateColumns: '80px 80px',
          width: 'fit-content',
        }}
      >
        <Button onClick={() => setOpenFilterDialog(true)}>
          <span style={{ fontSize: '10px' }}>Filter</span>
          <FilterList />
        </Button>
        <Button
          onClick={() => {
            setEbtFilter(undefined);
            props.setAvailableEBTs([]);
            ebtPageRef.current = 1;
            props.setRefreshEBTs(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Clear</span>
          <Clear />
        </Button>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          marginBottom: '15px',
          marginTop: '-30px',
          paddingRight: '40px',
          alignContent: 'end',
        }}
      >
        <Typography>{`${ebtCount} ${Number(ebtCount) === 1 ? 'result' : 'results'}`}</Typography>
      </Grid>

      <Grid
        id="pagination-container"
        container
        justifyContent="left"
        onScroll={async e => {
          const target = e.target as HTMLTextAreaElement;
          if (target.scrollTop > 8000) {
            if (isPaginatingRef.current) {
              target.scrollTop = 7800;
              return;
            }
            ebtPageRef.current += 1;
            setStartScrolling(true);
            isPaginatingRef.current = true;
            await paginateEBTs();
            isPaginatingRef.current = false;
            target.scrollTop = 200;
          }
          if (startScrolling && target.scrollTop < 100) {
            if (isPaginatingRef.current) {
              target.scrollTop = 300;
              return;
            }
            if (ebtPageRef.current > 1) {
              ebtPageRef.current -= 1;
            } else {
              setStartScrolling(false);
              return;
            }
            isPaginatingRef.current = true;
            await paginateEBTs();
            isPaginatingRef.current = false;
            target.scrollTop = 7800;
          }
        }}
        style={{
          paddingTop: '5px',
          marginLeft: '2%',
          border: 'inset #2D3748',
          maxWidth: '96%',
          width: 'fit-content, 30%',
          height: `1000px`,
          overflowX: 'hidden',
          overflowY: 'auto',
          flexFlow: 'column',
        }}
      >
        {EmailList({
          ...props,
          isLoading,
          setDeleting,
          boundariesById,
          geofencesById,
          layersById,
          microfencesById,
          editEBT,
        })}

        {WebhookList({
          ...props,
          isLoading,
          setDeleting,
          boundariesById,
          geofencesById,
          layersById,
          microfencesById,
          editEBT,
        })}
      </Grid>

      <Dialog open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Are you sure you want to delete this Event?</DialogTitle>
        <DialogActions>
          <Button
            onClick={async () => {
              if (deleting) {
                const deletable =
                  deleting.type === EBTNotifierTypeId.Email
                    ? props.availableEBTs?.find(
                        e => e.notifierType === EBTNotifierTypeId.Email && e.id === deleting.id,
                      )
                    : props.availableEBTs?.find(
                        w => w.notifierType === EBTNotifierTypeId.Webhook && w.id === deleting.id,
                      );
                if (deletable) {
                  deleteAction(deletable, deleting.type, deleting.id);
                } else {
                  await refreshAllEBTdata();
                }
              }
              setDeleting(undefined);
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70%',
            maxHeight: '80%',
            width: '650px',
            height: '80%',
          },
        }}
      >
        {EBTFilterDialog({
          ...props,
          setOpenFilterDialog,
          ebtFilter,
          setEbtFilter,
          enteredState,
          setEnteredState,
          dwellTimeMinimum,
          setDwellTimeMinimum,
        })}
      </Dialog>
    </>
  );
};
export default EBTList;
