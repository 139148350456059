import _ from 'lodash';
import { Feature } from 'ol';
import olMap from 'ol/Map';
import Point from 'ol/geom/Point';
import { Heatmap as HeatmapLayer, Vector as VectorLayer } from 'ol/layer';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { RefObject, SetStateAction, useEffect, useRef, useState, Dispatch } from 'react';
import { MapContainer } from '../../Components/Map/MapContainer/MapContainer';
import { ChangeMapSourceType } from '../../Components/Map/Toolbar/LayerTools/ChangeMapSourceType';
import { MapToolbar } from '../../Components/Map/Toolbar/MapToolbar';
import { ZoomIn } from '../../Components/Map/Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../../Components/Map/Toolbar/ZoomTools/ZoomOut';
import { FenceZIndexes } from '../../util/ZIndexes';
import { SimplifiedFenceEvent } from './EventHeatMap';
import { LoadIndicator } from '../Map/Toolbar/LayerTools/LoadIndicator';
import { MapSourceType } from '../Map/FeatureStyles';

export const HeatMap = ({
  data,
  map,
  mapId,
  mapRef,
  source,
  setSource,
  loadingMap,
}: {
  data?: SimplifiedFenceEvent[];
  map?: olMap | undefined;
  mapId: string;
  mapRef: RefObject<HTMLDivElement> | null;
  source: MapSourceType;
  setSource: Dispatch<SetStateAction<MapSourceType>>;
  loadingMap: boolean;
}) => {
  useEffect(() => {
    if (!map || !data) return () => {};

    const heatSource = new VectorSource();
    const pointSource = new VectorSource();
    const radiusM = 1;

    data.forEach(event => {
      if (!(event.latitude && event.longitude)) return;

      const pointFeature = new Feature({
        geometry: new Point(transform([event.longitude, event.latitude], 'EPSG:4326', 'EPSG:3857')),
        weight: 0.3,
      });
      pointFeature.setProperties(event);
      pointSource.addFeature(pointFeature);

      heatSource.addFeature(
        new Feature({
          geometry: new Point(
            transform([event.longitude, event.latitude], 'EPSG:4326', 'EPSG:3857'),
          ),
          weight: 1,
        }),
      );
    });

    const pointLayer = new VectorLayer({
      source: pointSource,
      style: new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: `rgba(17, 65, 255, 0.3)`,
          }),
          stroke: new Stroke({
            color: `rgba(17, 136, 255, 0.3)`,
            width: 2,
          }),
        }),
        zIndex: 1,
      }),
      zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
    });
    let heatMapLayer: HeatmapLayer | undefined = new HeatmapLayer({
      source: heatSource,
      radius: radiusM,
      opacity: 0.7,
    });

    const extent = heatSource.getExtent();
    if (extent && !extent.some(value => value === Infinity || value === -Infinity))
      map.getView().fit(extent, { padding: [50, 50, 50, 50] });

    map.getView().on(
      'change:resolution',
      _.debounce(event => {
        if (heatMapLayer) {
          const resolution = (event.target as { values_: { resolution: number } }).values_
            .resolution;
          const radius = Math.max(15, radiusM / resolution);
          if (heatMapLayer.get('radius') !== radius) heatMapLayer.set('radius', radius);
        }
        if (pointLayer) {
          const resolution = (event.target as { values_: { resolution: number } }).values_
            .resolution;
          const radius = Math.max(15, radiusM / resolution);
          if (pointLayer.get('radius') !== radius) pointLayer.set('radius', radius);
        }
      }, 10),
    );

    map.addLayer(pointLayer);
    map.addLayer(heatMapLayer);
    map.getView().adjustZoom(-1);
    map.getView().adjustZoom(+1);

    return () => {
      if (heatMapLayer) {
        map.removeLayer(pointLayer);
        map.removeLayer(heatMapLayer);
      }
      heatMapLayer = undefined;
    };
  }, [data, map]);

  return (
    <MapContainer id={mapId} ref={mapRef}>
      <MapToolbar>
        {loadingMap && <LoadIndicator what="geofences" />}
        <ChangeMapSourceType
          current={source}
          setType={value => {
            setSource(value);
          }}
        />
        <ZoomIn
          onClick={() => {
            map?.getView().adjustZoom(0.5);
          }}
        />
        <ZoomOut
          onClick={() => {
            map?.getView().adjustZoom(-0.5);
          }}
        />
      </MapToolbar>
    </MapContainer>
  );
};
