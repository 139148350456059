import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { TriggerTypes } from '../values';
import { EBTTriggerTypeId, EBTTriggerTypeValue } from '../../../util/enums';
import { EBTProps } from '../Props';
import { getTriggerTypeIcon } from '../Helpers';

const TriggerType = (props: EBTProps) => {
  return (
    <>
      <FormControl
        fullWidth
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        <InputLabel id="trigger-type">Event Trigger Type</InputLabel>
        <Select
          fullWidth
          labelId="trigger-type"
          id="trigger-type-dropdown"
          value={props.selectedTriggerType?.value ?? ''}
          label="Event Trigger Type"
          onChange={e => {
            const triggerType = TriggerTypes.find(
              trigger => trigger.value === (e.target.value as EBTTriggerTypeValue),
            );
            props.clearForm();
            props.setSelectedTriggerType(triggerType);
            if (triggerType?.id === EBTTriggerTypeId.Proximity) {
              const microfenceList = props.searchString
                ? props.availableMicrofences.filter(microfence =>
                    microfence.name?.toLowerCase().includes(props.searchString?.toLowerCase()),
                  )
                : props.availableMicrofences;
              props.setAvailableMicrofencesCount(microfenceList.length);
            }
          }}
        >
          {TriggerTypes.map(type => (
            <MenuItem key={type.id} value={type.value} disabled={type.disabled}>
              <Tooltip title={type.value}>
                <Typography
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 'calc(100% - 50px)',
                  }}
                >
                  {getTriggerTypeIcon(type.id)}
                  <span
                    style={{
                      padding: '10px',
                    }}
                  ></span>{' '}
                  {type.value}
                </Typography>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default TriggerType;
