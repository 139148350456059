import { Box, Paper, Select, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BACKGROUND } from '../../../Style/GeoMobyBaseTheme';
import { EBTNotifierTypeId, Method } from '../../../util/enums';
import InputContainer from '../../Global/InputContainer';
import { EBTProps } from '../Props';

const EventNotificationForm = (
  props: EBTProps & {
    showEventNotificationForm: boolean;
  },
) => {
  return (
    <>
      {props.showEventNotificationForm && (
        <Paper
          variant="outlined"
          style={{
            width: 'calc(100% - 10vw)',
            background: BACKGROUND,
          }}
        >
          <Box padding={2}>
            <form className={'trigger-action-form'} noValidate autoComplete="off">
              {(() => {
                switch (props.selectedNotifierType?.id) {
                  case EBTNotifierTypeId.Email:
                    if (props.webhookData) {
                      props.setWebhookData(undefined);
                    }
                    return (
                      <Stack spacing={1}>
                        <InputContainer
                          id="email-address"
                          label="Email address"
                          placeholder="abc@abc.com"
                          key={props.selectedNotifierType.id + '_address'}
                          name={props.selectedNotifierType.id + '_address'}
                          value={props.emailData?.address ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setEmailData({
                              address: e.target.value,
                              subject: props.emailData?.subject ?? '',
                              message: props.emailData?.message ?? '',
                            });
                          }}
                          required
                        />
                        <InputContainer
                          id="subject"
                          label="Subject"
                          key={props.selectedNotifierType.id + '_subject'}
                          name={props.selectedNotifierType.id + '_subject'}
                          value={props.emailData?.subject ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setEmailData({
                              address: props.emailData?.address ?? '',
                              subject: e.target.value,
                              message: props.emailData?.message ?? '',
                            });
                          }}
                          required
                        />
                        <InputContainer
                          id="body"
                          label="Message"
                          key={props.selectedNotifierType.id + '_body'}
                          name={props.selectedNotifierType.id + '_body'}
                          value={props.emailData?.message ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setEmailData({
                              address: props.emailData?.address ?? '',
                              subject: props.emailData?.subject ?? '',
                              message: e.target.value,
                            });
                          }}
                          multiline
                          required
                        />
                      </Stack>
                    );
                  case EBTNotifierTypeId.Notification:
                    return <></>;
                  case EBTNotifierTypeId.Webhook:
                    if (props.emailData) {
                      props.setEmailData(undefined);
                    }
                    return (
                      <Stack spacing={1}>
                        <ToggleButtonGroup
                          color="primary"
                          key={props.selectedNotifierType.id + '_method'}
                          value={props.webhookData?.method ?? Method.GET}
                          exclusive
                          onChange={e => {
                            props.setWebhookData({
                              method: (e.target as EventTarget & HTMLInputElement).value as Method,
                              url: props.webhookData?.url ?? '',
                            });
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton value={Method.GET}>{Method.GET}</ToggleButton>
                          <ToggleButton value={Method.POST}>{Method.POST}</ToggleButton>
                        </ToggleButtonGroup>
                        <InputContainer
                          id="webhook-action-url"
                          label="URL"
                          key={props.selectedNotifierType.id + '_url'}
                          name={props.selectedNotifierType.id + '_url'}
                          value={props.webhookData?.url ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setWebhookData({
                              method: props.webhookData?.method ?? Method.GET,
                              url: e.target.value,
                            });
                          }}
                          placeholder={'https://www.example.com.au/'}
                          required
                        />
                      </Stack>
                    );
                  default:
                    break;
                }
              })()}
            </form>
          </Box>
        </Paper>
      )}
    </>
  );
};
export default EventNotificationForm;
