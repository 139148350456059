import { GridRowData } from '@material-ui/data-grid';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Grid,
  Button,
  InputLabel,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import QuickSearch from '../../../../Common/QuickSearch';
import { CenteredProgress } from '../../../../Common/Sidebar';
import { ALL, ALL_GEOFENCES } from '../../../../util/constants';
import { EBTListenerTypeId, GeofenceEntityTypeId } from '../../../../util/enums';
import { geofenceList } from '../../../Geofence/GeofenceList';
import { EBTProps } from '../../Props';

const SelectAFence = (
  props: EBTProps & {
    isCrossing: boolean;
  },
) => {
  return (
    <>
      {props.selectedLayer && (
        <>
          <Box style={{ height: '429px' }}>
            {/* Fence Selection Type */}
            <FormControl
              style={{
                width: '100%',
                marginBottom: '15px',
              }}
            >
              <InputLabel id="fence-selection-option">Geofence Selection</InputLabel>
              <Select
                fullWidth
                labelId="fence-selection-action"
                id="fence-selection-dropdown"
                value={
                  props.selectedLocationListenerType === EBTListenerTypeId.Layer
                    ? ALL_GEOFENCES
                    : 'Select a Geofence'
                }
                label="Geofence Selection"
                disabled={!!props.eventIds}
                style={{
                  height: '60px',
                }}
                onChange={e => {
                  const listenerType =
                    e.target.value === ALL_GEOFENCES
                      ? EBTListenerTypeId.Layer
                      : EBTListenerTypeId.Geofence;
                  props.setSelectedLocationListenerType(listenerType);
                  if (listenerType === EBTListenerTypeId.Geofence) {
                    props.setAvailableFeaturesCount(0);
                    props.paginateGeofences({
                      layerId: props.selectedLayer?.id,
                      fenceType:
                        props.selectedGeofenceType?.id === ALL
                          ? props.isCrossing
                            ? [GeofenceEntityTypeId.Line]
                            : [GeofenceEntityTypeId.Polygon, GeofenceEntityTypeId.Multipolygon]
                          : [props.selectedGeofenceType?.id],
                      zone: props.selectedZone?.id === ALL ? undefined : props.selectedZone?.id,
                    });
                  } else {
                    props.setAvailableFeatures([]);
                    props.setAvailableFeaturesCount(0);
                  }
                }}
              >
                {[
                  { id: ALL, value: ALL_GEOFENCES },
                  { id: 'SELECT', value: 'Select a Geofence' },
                ].map(selection => (
                  <MenuItem key={selection.id} value={selection.value}>
                    {selection.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Fence Search */}
            {QuickSearch({
              showQuickSearch: props.selectedLocationListenerType === EBTListenerTypeId.Geofence,
              searchString: props.searchString,
              onChange: (searchName: string) => {
                props.featurePageRef.current = 1;
                props.setSearchString(searchName);
                props.debouncedOnSearchFence({
                  layerId: props.selectedLayer?.id,
                  fenceType:
                    props.selectedGeofenceType?.id === ALL
                      ? props.isCrossing
                        ? [GeofenceEntityTypeId.Line]
                        : [GeofenceEntityTypeId.Polygon, GeofenceEntityTypeId.Multipolygon]
                      : [props.selectedGeofenceType?.id],
                  zone: props.selectedZone?.id === ALL ? undefined : props.selectedZone?.id,
                  searchName,
                });
              },
            })}

            {/* Loading */}
            {props.featuresLoading && (
              <Grid
                container
                justifyContent={'center'}
                style={{
                  marginTop: '120px',
                }}
              >
                <CenteredProgress />
              </Grid>
            )}

            {/* Fence List */}
            {!props.featuresLoading &&
              props.selectedLocationListenerType === EBTListenerTypeId.Geofence && (
                <Grid
                  container
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '50% 50%',
                    marginTop: '15px',
                  }}
                >
                  <InputLabel id="select-geofence">
                    {props.availableFeaturesCount > 0
                      ? 'Select a Geofence'
                      : 'No geofences available'}
                  </InputLabel>
                  <Typography style={{ justifySelf: 'self-end' }}>{`${
                    props.availableFeaturesCount
                  } ${
                    Number(props.availableFeaturesCount) === 1 ? 'result' : 'results'
                  }`}</Typography>
                </Grid>
              )}
            {!props.featuresLoading &&
              props.selectedLocationListenerType === EBTListenerTypeId.Geofence &&
              props.availableLayers.length > 0 && (
                <Grid
                  container
                  direction={'row'}
                  style={{
                    marginTop: '10px',
                    height: 'fit-content',
                    maxHeight: '239px',
                    overflowY: 'hidden',
                  }}
                >
                  {geofenceList({
                    availableGeofences: props.availableFeatures,
                    selectedFence: props.selectedFeature,
                    showList: props.availableFeaturesCount > 0,
                    onClick: (fence: GridRowData) => {
                      props.setSelectedFeature(prevFence =>
                        prevFence === fence ? undefined : fence,
                      );
                    },
                  })}
                </Grid>
              )}
          </Box>

          {/* Next and Previous */}
          {props.selectedLocationListenerType === EBTListenerTypeId.Geofence && (
            <Grid
              container
              style={{
                display: 'grid',
                gridTemplateColumns: '60px 60px',
                gap: '10px',
                justifyContent: 'right',
                paddingRight: '21px',
                paddingTop: '12px',
                marginBottom: '-40px',
              }}
            >
              <Button
                variant="outlined"
                disabled={props.availableFeaturesCount === 0}
                onClick={e => {
                  props.featurePageRef.current =
                    props.featurePageRef.current <= 1 ? 1 : props.featurePageRef.current - 1;
                  props.debouncedOnSearchFence({
                    layerId: props.selectedLayer?.id,
                    fenceType:
                      props.selectedGeofenceType?.id === ALL
                        ? props.isCrossing
                          ? [GeofenceEntityTypeId.Line]
                          : [GeofenceEntityTypeId.Polygon, GeofenceEntityTypeId.Multipolygon]
                        : [props.selectedGeofenceType?.id],
                    zone: props.selectedZone?.id === ALL ? undefined : props.selectedZone?.id,
                    searchName: props.searchString,
                  });
                  e.stopPropagation();
                }}
              >
                <ChevronLeft />
              </Button>
              <Button
                variant="outlined"
                disabled={props.availableFeaturesCount === 0}
                onClick={e => {
                  props.featurePageRef.current =
                    5 * props.featurePageRef.current >= props.availableFeaturesCount
                      ? props.featurePageRef.current
                      : props.featurePageRef.current + 1;
                  props.debouncedOnSearchFence({
                    layerId: props.selectedLayer?.id,
                    fenceType:
                      props.selectedGeofenceType?.id === ALL
                        ? props.isCrossing
                          ? [GeofenceEntityTypeId.Line]
                          : [GeofenceEntityTypeId.Polygon, GeofenceEntityTypeId.Multipolygon]
                        : [props.selectedGeofenceType?.id],
                    zone: props.selectedZone?.id === ALL ? undefined : props.selectedZone?.id,
                    searchName: props.searchString,
                  });
                  e.stopPropagation();
                }}
              >
                <ChevronRight />
              </Button>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
export default SelectAFence;
