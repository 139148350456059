import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  FC,
  MutableRefObject,
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { GeofenceFilter, SearchType, GeomobyOverride, FenceZoneType, NameId } from '../../../types';
import { Geometry } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { ReassignedFence } from '../../../FeatureStyles';
import { GeomobyProperties, PropertiesTable } from '../Geofence/GeomobyProperties';
import { GeomobyOverrides, OverridesTable } from '../Geofence/GeomobyOverrides';
import {
  BufferShapeType,
  EntityType,
  FenceGeometryType,
  FenceZone,
  FenceZoneValue,
  GeofenceEntityTypeId,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { geometryTypeOfEntity } from '../../../commons';
import { Delete, Download, Edit, NearMe } from '@mui/icons-material';
import { FenceZoneTypes } from '../../../values';
import { MICROFENCE_LAYER_ID } from '../../../BeaconUtils';
import { useMobile } from '../../../../../util/useMobile';
import { debounce } from 'lodash';
import { useAtomValue } from 'jotai';
import { CID, PID } from '../../../../../store/user';
import { TRIGGERS_URL } from '../../../../../store/url';
import { AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import axios from 'axios';
import { Extent } from 'ol/extent';
import { SearchListProps, SelectedGeofenceProps } from '../../Props';
import {
  ALL_LAYERS,
  DEFAULT_BUFFER_METERS,
  FRESH,
  UNKNOWN_LAYER,
} from '../../../../../util/constants';
import { getZoneIcon } from '../../../Helpers';
import {
  lineLimitStyle,
  PRIMARY,
  SECONDARY,
  truncationStyle,
  WHITE,
} from '../../../../../Style/GeoMobyBaseTheme';
import { Map as olMap } from 'ol';

export const useDisplayGeofence = (
  props: SelectedGeofenceProps &
    SearchListProps & {
      downloadGeoJSON: () => Promise<void>;
      geofenceHasNoLayerAssigned: boolean;
      previousGeofenceHasNoLayerAssigned: boolean;
    },
) => {
  // Just temporary, until Gus carries out phase 2 of the new zones LTP-1165
  const isNotABufferBreachOrClearedZone = (): boolean => {
    return !(
      props.selectedGeofence?.zone === FenceZone.buffer ||
      props.selectedGeofence?.zone === FenceZone.breach ||
      props.selectedGeofence?.zone === FenceZone.cleared
    );
  };

  return (
    <>
      {!props.createEditLayer && !props.createEditFence && props.selectedGeofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Box
            style={{
              marginTop: '5px',
            }}
            color="primary.main"
          >
            {getZoneIcon(
              geometryTypeOfEntity(props.selectedGeofence) === FenceGeometryType.Multipolygon
                ? FenceZone.cleared
                : props.selectedGeofence.zone,
            )}
          </Box>

          <Grid
            container
            direction="row"
            style={{
              width: '100%',
              marginBottom:
                (geometryTypeOfEntity(props.selectedGeofence) !== FenceGeometryType.Multipolygon &&
                  isNotABufferBreachOrClearedZone()) ||
                geometryTypeOfEntity(props.selectedGeofence) === FenceGeometryType.Line
                  ? '0px'
                  : '10px',
              marginTop:
                (geometryTypeOfEntity(props.selectedGeofence) !== FenceGeometryType.Multipolygon &&
                  isNotABufferBreachOrClearedZone()) ||
                geometryTypeOfEntity(props.selectedGeofence) === FenceGeometryType.Line
                  ? '0px'
                  : '-37px',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip title={'NAVIGATE TO'}>
              <IconButton
                color="primary"
                onClick={() => {
                  if (!props.selectedGeofence) return;
                  props.animateToFeature(props.selectedGeofence);
                }}
              >
                <NearMe />
              </IconButton>
            </Tooltip>

            <Tooltip title={'DOWNLOAD'}>
              <span>
                <IconButton
                  id="download-geojson"
                  color="primary"
                  disabled={
                    props.geofenceHasNoLayerAssigned || props.previousGeofenceHasNoLayerAssigned
                  }
                  style={{
                    justifySelf: 'end',
                  }}
                  onClick={() => props.downloadGeoJSON()}
                >
                  <Download />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={RequestType.Edit}>
              <IconButton
                color="primary"
                onClick={() => {
                  const foundLayer = props.layerIds.find(
                    lyr => lyr.id === props.selectedGeofence?.layerId,
                  );
                  props.setSelectedLayer(foundLayer);
                  if (!foundLayer) {
                    props.resetlayer();
                  }
                  props.setFenceNameInput(props.selectedGeofence?.name);
                  props.setBufferOffset(undefined);
                  props.setBufferShape(BufferShapeType.Circle);
                  props.setCreateEditFence(RequestType.Edit);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>

            {props.selectedGeofence.zone !== FenceZone.buffer && (
              <Tooltip title={RequestType.Delete}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    const foundLayer = props.layerIds.find(
                      lyr => lyr.id === props.selectedGeofence?.layerId,
                    );
                    props.setSelectedLayer(foundLayer);
                    if (!foundLayer) {
                      props.resetlayer();
                    }
                    props.setDeleting(EntityType.Geofence);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            spacing={1}
            marginLeft={0}
            justifyContent="left"
            alignItems="center"
          >
            <Tooltip title={props.selectedGeofence.name} style={lineLimitStyle}>
              <Typography variant="h5">{props.selectedGeofence.name}</Typography>
            </Tooltip>
          </Grid>

          {!props.geofenceHasNoLayerAssigned && (
            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: '90%',
                alignContent: 'start',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: WHITE,
                }}
                onClick={() => {
                  const olmap = props.mapState?.map;
                  if (!props.selectedGeofence || !olmap) return;
                  props.setSelectedGeofence(undefined);
                  props.setFenceNameInput('');
                  props.setSelectedMicrofence(undefined);
                  props.setSearchType({
                    id: SearchTypeIDs.Geofences,
                    value: SearchTypeValue.Geofences,
                  });
                  props.setGeofenceFilter(undefined);
                  props.pageRef.current = 1;
                  props.resetlayer();
                  props.deselectFences(olmap);
                }}
              >
                {props.layerIds.find(lyr => lyr.id === props.selectedGeofence?.layerId)?.name ?? ''}
              </Button>
            </Grid>
          )}

          {props.geofenceHasNoLayerAssigned && (
            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                color: SECONDARY,
              }}
            >
              No group assigned
            </Grid>
          )}

          {props.displayGeomobyProperties && (
            <>
              <Tooltip title={'Geofence Properties'}>
                <Typography style={{ color: PRIMARY }}>Geofence Properties</Typography>
              </Tooltip>
              <PropertiesTable rows={props.displayGeomobyProperties}></PropertiesTable>
            </>
          )}
          {props.displayGeomobyOverrides && (
            <Grid
              style={{
                marginTop: '10px',
              }}
            >
              <Tooltip title={'Override Rules'}>
                <Typography style={{ color: PRIMARY }}>Override Rules</Typography>
              </Tooltip>
              <OverridesTable rows={props.displayGeomobyOverrides}></OverridesTable>
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
};

export const useEditGeofence = (
  props: SelectedGeofenceProps &
    SearchListProps & {
      geofenceHasNoLayerAssigned: boolean;
      previousGeofenceHasNoLayerAssigned: boolean;
    },
) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const [properties, setProperties] = useState<{ label: string; id: string; isCustom: boolean }[]>(
    [],
  );
  const bufferOffsetRef = useRef<HTMLInputElement>(null);
  const fenceNameRef = useRef<HTMLInputElement>(null);
  const isMobile = useMobile();

  const debounceUpdateGeofence = useRef(
    debounce(
      async (
        olmap: olMap,
        id: string,
        name: string,
        type: FenceGeometryType | undefined,
        layerId: string,
        zone: FenceZone | undefined,
      ) => {
        await props.updateFenceIdentifiers(
          olmap,
          id,
          name,
          type,
          layerId,
          zone,
          undefined,
          undefined,
        );
      },
      500,
      { leading: true },
    ),
  ).current;

  const getGeofenceProperties = useCallback(async () => {
    const defaultProperties = (
      await axios.get<{ label: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/geomoby`,
        authedConfig,
      )
    ).data;
    const projectProperties = (
      await axios.get<{ label: string; id: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/project`,
        authedConfig,
      )
    ).data;

    setProperties(
      [
        ...defaultProperties.map(p => {
          return { id: p.label, label: p.label, isCustom: false };
        }),
        ...projectProperties.map(p => {
          return {
            ...p,
            isCustom: true,
          };
        }),
      ].sort((a, b) => a.label.localeCompare(b.label)),
    );
  }, [cid, pid, triggersUrl, authedConfig]);

  const findBufferFromBreach = (
    olmap: olMap,
    breachId: string,
    layerId: string,
  ): string | undefined => {
    const layerFromMap = props.getLayerFromMap(olmap, layerId);
    return [
      ...props.availableGeofences,
      ...(layerFromMap
        ?.getSource()
        ?.getFeatures()
        ?.map(f => f.getProperties()) ?? []),
    ]?.find(f => f.parentId === breachId)?.id;
  };

  const getZonesSelection = (): FenceZoneType[] => {
    if (
      props.selectedGeofence?.zone === FenceZone.breach ||
      props.selectedGeofence?.zone === FenceZone.cleared
    ) {
      return FenceZoneTypes.filter(
        zone => zone.id === props.selectedGeofence?.zone || zone.id === FenceZone.none,
      );
    } else if (props.hasFences) {
      return FenceZoneTypes;
    }
    return FenceZoneTypes.filter(f => f.id !== FenceZone.buffer);
  };

  useEffect(() => {
    getGeofenceProperties();
  }, [getGeofenceProperties]);

  return (
    <>
      {props.createEditFence && props.selectedGeofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Grid
            container
            direction="row"
            sx={{
              '& .MuiTypography-root': {
                marginLeft: '0px',
                width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                ...truncationStyle,
              },
              '& .MuiStack-root': {
                width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                justifyContent: 'start',
              },
              marginTop: '10px',
            }}
          >
            <FormControl
              style={{
                width: '100%',
                marginBottom: '15px',
              }}
            >
              <InputLabel id="rename">Name</InputLabel>
              <OutlinedInput
                id="rename"
                label="Name"
                key={'rename'}
                name={'rename'}
                inputRef={fenceNameRef}
                placeholder="Geofence 1"
                disabled={
                  props.selectedGeofence.zone === FenceZone.buffer ||
                  props.geofenceHasNoLayerAssigned ||
                  props.previousGeofenceHasNoLayerAssigned
                }
                value={props.fenceNameInput}
                onChange={async ({ target: { value } }) => {
                  props.setFenceNameInput(value);
                  const olmap = props.mapState?.map;
                  if (!props.selectedGeofence || !olmap) return;
                  props.selectedGeofence.name = value;
                  debounceUpdateGeofence(
                    olmap,
                    props.selectedGeofence.id,
                    props.selectedGeofence.name,
                    geometryTypeOfEntity(props.selectedGeofence),
                    props.selectedGeofence.layerId,
                    props.selectedGeofence.zone,
                  );
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    props.saveAllChanges();
                  }
                }}
              />
            </FormControl>
          </Grid>

          {geometryTypeOfEntity(props.selectedGeofence) === FenceGeometryType.Polygon &&
            props.selectedGeofence.zone !== FenceZone.buffer && (
              <FormControl
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="zone-type-option">Zone</InputLabel>
                <Select
                  fullWidth
                  labelId="zone-type-action"
                  id="zone-type-dropdown"
                  disabled={
                    props.geofenceHasNoLayerAssigned || props.previousGeofenceHasNoLayerAssigned
                  }
                  value={
                    FenceZoneTypes.find(z => String(z.id) === props.selectedGeofence?.zone)
                      ?.value ?? ''
                  }
                  label="Zone"
                  style={{
                    height: '60px',
                  }}
                  onChange={async e => {
                    const olmap = props.mapState?.map;
                    if (!props.selectedGeofence || !olmap || !props.selectedLayer?.id) return;
                    const zoneType = FenceZoneTypes.find(
                      z => z.value === (e.target.value as FenceZoneValue),
                    );
                    if (!zoneType) return;
                    if (zoneType.id === FenceZone.breach) {
                      await props.setAsBreachZone(
                        olmap,
                        props.selectedGeofence,
                        undefined,
                        props.bufferOffset ?? DEFAULT_BUFFER_METERS,
                        props.bufferShape,
                      );
                    } else {
                      props.setBufferOffset(undefined);
                      if (zoneType.id === FenceZone.buffer) {
                        await props.setAsBufferZone(olmap, props.selectedGeofence);
                      } else if (
                        zoneType.id === FenceZone.cleared ||
                        zoneType.id === FenceZone.loading ||
                        zoneType.id === FenceZone.dumping ||
                        zoneType.id === FenceZone.maintenance
                      ) {
                        await props.setZone(olmap, props.selectedGeofence, zoneType.id);
                      } else if (zoneType.id === FenceZone.none) {
                        if (props.selectedGeofence.zone === FenceZone.breach) {
                          const bufferId = findBufferFromBreach(
                            olmap,
                            props.selectedGeofence?.id,
                            props.selectedLayer.id,
                          );
                          if (!bufferId) return;
                          await props.unsetAsBreachZone(olmap, props.selectedGeofence, bufferId);
                        } else if (
                          props.selectedGeofence.zone === FenceZone.cleared ||
                          props.selectedGeofence.zone === FenceZone.loading ||
                          props.selectedGeofence.zone === FenceZone.dumping ||
                          props.selectedGeofence.zone === FenceZone.maintenance
                        ) {
                          await props.unsetZone(olmap, props.selectedGeofence);
                        }
                      }
                    }

                    props.setSelectedGeofence({
                      ...props.selectedGeofence,
                      zone: zoneType.id as FenceZone,
                    });
                    props.setLayersHaveChanged(true);
                  }}
                >
                  {getZonesSelection().map(zone => (
                    <MenuItem key={zone.id} value={zone.value}>
                      <Tooltip title={zone.value}>
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                            '& .MuiSvgIcon-root': {
                              marginBottom: '-7px',
                            },
                          }}
                        >
                          {getZoneIcon(zone.id)}
                          <span
                            style={{
                              padding: '10px',
                            }}
                          ></span>{' '}
                          {zone.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

          {props.selectedGeofence?.zone === FenceZone.breach && (
            <Grid
              style={{
                marginTop: '10px',
              }}
            >
              <Tooltip title={'Set Buffer Zone'}>
                <Typography style={{ color: PRIMARY }}>Set Buffer Zone</Typography>
              </Tooltip>

              <Grid
                container
                style={{
                  display: 'grid',
                  gap: '2%',
                  gridTemplateColumns: '49% 49%',
                  marginBottom: '20px',
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={props.bufferShape}
                  sx={{
                    '& .MuiButtonBase-root': {
                      width: '50%',
                    },
                  }}
                  exclusive
                  onChange={async e => {
                    const olmap = props.mapState?.map;
                    if (!props.selectedGeofence || !olmap || !props.selectedLayer?.id) return;
                    const newShape = (e.target as EventTarget & HTMLInputElement)
                      .value as BufferShapeType;
                    props.setBufferShape(newShape);
                    props.setIsLoading(true);
                    const bufferId = findBufferFromBreach(
                      olmap,
                      props.selectedGeofence?.id,
                      props.selectedLayer.id,
                    );
                    if (bufferId) {
                      await props.setAsBreachZone(
                        olmap,
                        props.selectedGeofence,
                        bufferId,
                        props.bufferOffset ?? DEFAULT_BUFFER_METERS,
                        newShape,
                      );
                    }
                    props.setLayersHaveChanged(true);
                    props.setIsLoading(false);
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="Circle">Circle</ToggleButton>
                  <ToggleButton value="Scaled">Scaled</ToggleButton>
                </ToggleButtonGroup>

                <FormControl>
                  <InputLabel id="offset">Offset</InputLabel>
                  <OutlinedInput
                    id="offset"
                    label="Offset"
                    key={'offset'}
                    name={'offset'}
                    inputRef={bufferOffsetRef}
                    value={props.bufferOffset ?? DEFAULT_BUFFER_METERS}
                    onChange={async ({ target: { value } }) => {
                      const olmap = props.mapState?.map;
                      if (!props.selectedGeofence || !olmap || !props.selectedLayer?.id) return;
                      if (!isNaN(Number(value))) {
                        props.setBufferOffset(Number(value));
                        const bufferId = findBufferFromBreach(
                          olmap,
                          props.selectedGeofence?.id,
                          props.selectedLayer.id,
                        );
                        if (!bufferId) return;
                        await props.setAsBreachZone(
                          olmap,
                          props.selectedGeofence,
                          bufferId,
                          Number(value),
                          props.bufferShape,
                        );
                        props.setLayersHaveChanged(true);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}

          {/* Geofence Properties */}
          {props.selectedGeofence?.geomobyProperties && props.mapState?.map && (
            <GeomobyProperties
              olmap={props.mapState.map}
              selectedGeofence={props.selectedGeofence}
              displayGeomobyProperties={props.displayGeomobyProperties}
              setDisplayGeomobyProperties={props.setDisplayGeomobyProperties}
              properties={properties}
              saveAllChanges={props.saveAllChanges}
              updateGeomobyProperties={props.updateGeomobyProperties}
            />
          )}

          {/* Geofence Overrides */}
          {props.selectedGeofence?.geomobyOverrides && props.mapState?.map && (
            <GeomobyOverrides
              olmap={props.mapState.map}
              selectedGeofence={props.selectedGeofence}
              displayGeomobyOverrides={props.displayGeomobyOverrides.map(
                (override: GeomobyOverride, index: number) => {
                  return {
                    ...override,
                    index,
                  };
                },
              )}
              setDisplayGeomobyOverrides={props.setDisplayGeomobyOverrides}
              properties={properties}
              saveAllChanges={props.saveAllChanges}
              updateGeomobyOverrides={props.updateGeomobyOverrides}
            />
          )}

          {/* Reassign to another layer */}
          {props.selectedGeofence.zone !== FenceZone.buffer &&
            ((!props.selectedGeofence.id?.includes(FRESH) &&
              props.selectedGeofence?.layerId !== UNKNOWN_LAYER) ||
              (props.selectedGeofence.id?.includes(FRESH) &&
                (props.geofenceHasNoLayerAssigned ||
                  props.previousGeofenceHasNoLayerAssigned))) && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  style={{
                    margin: '50px 0px 10px 0px',
                  }}
                >
                  <InputLabel id="reassign-layer">{`${
                    props.geofenceHasNoLayerAssigned
                      ? 'Assign to a group'
                      : 'Reassign to another group'
                  }`}</InputLabel>
                  <Select
                    id="reassign-layer"
                    labelId="reassign-layer"
                    label={`${
                      props.geofenceHasNoLayerAssigned
                        ? 'Assign to a group'
                        : 'Reassign to another group'
                    }`}
                    fullWidth
                    onChange={async e => {
                      const olmap = props.mapState?.map;
                      if (!props.selectedGeofence || !olmap) return;
                      if (
                        props.geofenceHasNoLayerAssigned ||
                        props.previousGeofenceHasNoLayerAssigned
                      ) {
                        props.setFreshGeofences([
                          {
                            ...props.selectedGeofence,
                            layerId: e.target.value,
                            previousLayer: UNKNOWN_LAYER,
                          },
                        ]);
                        const filter = { ...props.geofenceFilter, layerId: e.target.value };
                        await props.paginateGeofences(filter as GeofenceFilter);
                        props.setSelectedGeofence(undefined);
                        props.setSelectedLayer(
                          props.layerIds.find(lyr => lyr.id === e.target.value),
                        );
                        props.moveUnknownFenceToExistingLayer(olmap, e.target.value);
                        props.setGeofenceFilter(filter);
                      } else {
                        props.setReassignedFences([
                          ...props.reassignedFences.filter(
                            f => f.id !== props.selectedGeofence?.id,
                          ),
                          {
                            id: props.selectedGeofence.id,
                            newLayerId: e.target.value,
                            type: (geometryTypeOfEntity(props.selectedGeofence) ??
                              FenceGeometryType.Polygon) as FenceGeometryType,
                          },
                        ]);
                        props.setReassignedLayerId(e.target.value);
                      }
                      props.setLayersHaveChanged(true);
                    }}
                    value={props.reassignedLayerId ?? ''}
                  >
                    <MenuItem value="none" />
                    {props.layerIds
                      ?.filter(l => l.id !== MICROFENCE_LAYER_ID && l.id !== UNKNOWN_LAYER)
                      .sort((a, b) => a.name?.localeCompare(b.name))
                      .map(layer => (
                        <MenuItem key={layer.id} value={layer.id}>
                          <Tooltip title={layer.name}>
                            <Typography
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 30px)',
                              }}
                            >
                              {layer.name}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
        </Paper>
      )}
    </>
  );
};

export const useSelectedGeofence = (props: SelectedGeofenceProps & SearchListProps) => {
  const geofenceHasNoLayerAssigned = useMemo(
    () =>
      props.selectedGeofence?.layerId === ALL_LAYERS ||
      props.selectedGeofence?.layerId === UNKNOWN_LAYER,
    [props.selectedGeofence?.layerId],
  );
  const previousGeofenceHasNoLayerAssigned = useMemo(
    () =>
      props.selectedGeofence?.previousLayer === ALL_LAYERS ||
      props.selectedGeofence?.previousLayer === UNKNOWN_LAYER,
    [props.selectedGeofence?.previousLayer],
  );

  const downloadGeoJSON = async () => {
    const olmap = props.mapState?.map;
    if (!props.selectedGeofence || !olmap) return;
    const type = geometryTypeOfEntity(props.selectedGeofence);
    const geofence = (
      await props.findFeature(
        olmap,
        props.selectedGeofence?.id,
        props.selectedGeofence?.layerId,
        type,
      )
    )?.getProperties();
    if (!geofence?.points || !type) return;

    const layerId = props.selectedGeofence.layerId ?? props.selectedLayer?.id;
    const layerName = props.layerIds.find((lyr: NameId) => lyr.id === layerId)?.name;
    const geoJSON = {
      type: 'Feature',
      geometry: {
        type,
        coordinates: geofence.points.coordinates,
      },
      properties: {
        id: geofence.id,
        name: geofence.name,
        geomobyProperties: geofence.geomobyProperties,
        geomobyOverrides: geofence.geomobyOverrides,
        zone: props.selectedGeofence.zone,
        parentId: props.selectedGeofence.parentId,
        layerId,
        layerName,
      },
    };

    const jsonString: string = JSON.stringify(geoJSON, null, 2);
    const dataUri = window.URL.createObjectURL(
      new Blob([jsonString], { type: 'application/json' }),
    );
    const link = document.createElement('a');
    link.download = `${props.selectedGeofence.name}.json`;
    link.href = dataUri;
    link.click();
  };

  return (
    <>
      {useDisplayGeofence({
        ...props,
        geofenceHasNoLayerAssigned,
        previousGeofenceHasNoLayerAssigned,
        downloadGeoJSON,
      })}
      {useEditGeofence({
        ...props,
        geofenceHasNoLayerAssigned,
        previousGeofenceHasNoLayerAssigned,
      })}
    </>
  );
};
