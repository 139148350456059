import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { MICROFENCE_LAYER_ID } from '../Map/BeaconUtils';
import { GridRowData } from '@material-ui/data-grid';
import {
  EBT,
  EBTNotifierType,
  EmailType,
  EventIds,
  GeofenceType,
  TriggerOption,
  TriggerType,
  WebhookType,
} from './types';
import EBTList from '../Events/List/EBTList';
import { getVectorLayers } from '../Map/Editor/EditorFunctions';
import {
  initialExtentInDegrees,
  EBT_DISPLAY_LIMIT,
  ALL,
  ALL_GEOFENCE_ZONES,
  ALL_GEOFENCE_TYPES,
} from '../../util/constants';
import { EBTListenerTypeId, EntityType } from '../../util/enums';
import EBTForm from '../Events/Form/EBTForm';
import { FenceZoneType, GeofenceFilter, NameId } from '../Map/types';
import { debounce } from 'lodash';

const Events = () => {
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const [availableEBTs, setAvailableEBTs] = useState<EBT[]>([]);
  const [availableFeaturesCount, setAvailableFeaturesCount] = useState<number>(0);
  const [availableFeatures, setAvailableFeatures] = useState<GridRowData[]>([]);
  // Until Microfences are paginated, it doesn't make sense to get retreiving the microfence list. In the meantime we should not use availableFeatures for microfences.
  const [availableMicrofencesCount, setAvailableMicrofencesCount] = useState<number>(0);
  const [availableMicrofences, setAvailableMicrofences] = useState<GridRowData[]>([]);
  const [availableLayers, setAvailableLayers] = useState<NameId[]>([]);

  const [customIsEqual, setCustomIsEqual] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<EmailType | undefined>();
  const [eventIds, setEventIds] = useState<EventIds | undefined>();
  const [eventName, setEventName] = useState<string | undefined>();
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [maxDate, setMaxDate] = useState<Date | null>();
  const [minDate, setMinDate] = useState<Date | null>();
  const [searchString, setSearchString] = useState<string | undefined>();

  const [selectedFeature, setSelectedFeature] = useState<GridRowData | undefined>();
  const [selectedGeofenceType, setSelectedGeofenceType] = useState<
    GeofenceType | { id: typeof ALL; value: typeof ALL_GEOFENCE_TYPES }
  >({ id: ALL, value: ALL_GEOFENCE_TYPES });
  const [selectedLayer, setSelectedLayer] = useState<NameId | undefined>();
  const [selectedLocationListenerType, setSelectedLocationListenerType] =
    useState<EBTListenerTypeId>(EBTListenerTypeId.Layer);
  const [selectedNotifierType, setSelectedNotifierType] = useState<EBTNotifierType | undefined>();
  const [selectionIterator, setSelectionIterator] = useState<number>(0);
  const [selectedTriggerType, setSelectedTriggerType] = useState<TriggerType | undefined>();
  const [selectedTriggerOption, setSelectedTriggerOption] = useState<TriggerOption | undefined>();
  const [selectedZone, setSelectedZone] = useState<
    FenceZoneType | { id: typeof ALL; value: typeof ALL_GEOFENCE_ZONES }
  >({ id: ALL, value: ALL_GEOFENCE_ZONES });

  const [refreshEBTs, setRefreshEBTs] = useState<boolean>(true);
  const [triggerValue, setTriggerValue] = useState<string | undefined>();
  const [webhookData, setWebhookData] = useState<WebhookType | undefined>(undefined);

  const featurePageRef = useRef<number>(1);

  const getMicrofences = useCallback(async () => {
    const microfences = (
      await axios.get<GridRowData[]>(`${triggersUrl}/${cid}/${pid}/microfences`, authedConfig)
    ).data;
    setAvailableMicrofences(microfences.sort((a, b) => a.name.localeCompare(b.name)));
    setAvailableMicrofencesCount(microfences.length);
  }, [triggersUrl, authedConfig, cid, pid]);

  const getLayers = useCallback(async () => {
    const layers: NameId[] | undefined = await getVectorLayers(
      triggersUrl,
      authedConfig,
      { cid, pid },
      { latitude: 0, longitude: 0, extentInDegrees: initialExtentInDegrees },
    );
    setAvailableLayers((layers ?? []).sort((a, b) => a.name.localeCompare(b.name)));
  }, [triggersUrl, authedConfig, cid, pid]);

  const paginateGeofences = useCallback(
    async (filter: GeofenceFilter) => {
      if (!filter.layerId || filter.layerId === MICROFENCE_LAYER_ID) return;
      setAvailableFeatures([]);
      setAvailableFeaturesCount(0);
      setFeaturesLoading(true);

      let filters = `?perPage=${EBT_DISPLAY_LIMIT}`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${
              typeof value === 'object'
                ? Array.isArray(value)
                  ? String(value)
                  : value.id
                : value.toString()
            }`,
          );
        }
      });

      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${featurePageRef.current}${filters}`,
          authedConfig,
        )
      ).data;
      setFeaturesLoading(false);
      setAvailableFeatures(geofences);
      setAvailableFeaturesCount(Number(count));
    },
    [triggersUrl, authedConfig, cid, pid],
  );

  const debouncedOnSearchFence = useRef(
    debounce(
      (filter: GeofenceFilter) => {
        paginateGeofences(filter);
      },
      1000,
      { leading: true },
    ),
  ).current;

  const clearForm = useCallback(() => {
    setAvailableFeaturesCount(0);
    setAvailableMicrofencesCount(0);
    setAvailableFeatures([]);

    setEmailData(undefined);
    setWebhookData(undefined);
    setEventIds(undefined);
    setEventName(undefined);

    setSelectedFeature(undefined);
    setSelectedGeofenceType({ id: ALL, value: ALL_GEOFENCE_TYPES });
    setSelectedLayer(undefined);
    setSelectedLocationListenerType(EBTListenerTypeId.Layer);
    setSelectedNotifierType(undefined);
    setSelectionIterator(0);
    setSelectedTriggerType(undefined);
    setSelectedTriggerOption(undefined);
    setSelectedZone({ id: ALL, value: ALL_GEOFENCE_ZONES });
    setTriggerValue(undefined);
    setSearchString(undefined);

    featurePageRef.current = 1;
    setIsEditing(false);
  }, []);

  useEffect(() => {
    getLayers();
    getMicrofences();
  }, [getLayers, getMicrofences]);

  return (
    <>
      <EBTForm
        availableEBTs={availableEBTs}
        setAvailableEBTs={setAvailableEBTs}
        availableFeatures={availableFeatures}
        setAvailableFeatures={setAvailableFeatures}
        availableFeaturesCount={availableFeaturesCount}
        setAvailableFeaturesCount={setAvailableFeaturesCount}
        availableMicrofences={availableMicrofences}
        setAvailableMicrofences={setAvailableMicrofences}
        availableMicrofencesCount={availableMicrofencesCount}
        setAvailableMicrofencesCount={setAvailableMicrofencesCount}
        availableLayers={availableLayers}
        setAvailableLayers={setAvailableLayers}
        customIsEqual={customIsEqual}
        setCustomIsEqual={setCustomIsEqual}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        emailData={emailData}
        setEmailData={setEmailData}
        eventIds={eventIds}
        setEventIds={setEventIds}
        eventName={eventName}
        setEventName={setEventName}
        featuresLoading={featuresLoading}
        setFeaturesLoading={setFeaturesLoading}
        selectedTriggerOption={selectedTriggerOption}
        setSelectedTriggerOption={setSelectedTriggerOption}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectionIterator={selectionIterator}
        setSelectionIterator={setSelectionIterator}
        selectedLocationListenerType={selectedLocationListenerType}
        setSelectedLocationListenerType={setSelectedLocationListenerType}
        selectedGeofenceType={selectedGeofenceType}
        setSelectedGeofenceType={setSelectedGeofenceType}
        selectedZone={selectedZone}
        setSelectedZone={setSelectedZone}
        triggerValue={triggerValue}
        setTriggerValue={setTriggerValue}
        selectedNotifierType={selectedNotifierType}
        setSelectedNotifierType={setSelectedNotifierType}
        refreshEBTs={refreshEBTs}
        searchString={searchString}
        setSearchString={setSearchString}
        setRefreshEBTs={setRefreshEBTs}
        selectedTriggerType={selectedTriggerType}
        setSelectedTriggerType={setSelectedTriggerType}
        webhookData={webhookData}
        setWebhookData={setWebhookData}
        featurePageRef={featurePageRef}
        clearForm={clearForm}
        paginateGeofences={paginateGeofences}
        debouncedOnSearchFence={debouncedOnSearchFence}
      />

      <EBTList
        availableEBTs={availableEBTs}
        setAvailableEBTs={setAvailableEBTs}
        availableFeatures={availableFeatures}
        setAvailableFeatures={setAvailableFeatures}
        availableFeaturesCount={availableFeaturesCount}
        setAvailableFeaturesCount={setAvailableFeaturesCount}
        availableMicrofences={availableMicrofences}
        setAvailableMicrofences={setAvailableMicrofences}
        availableMicrofencesCount={availableMicrofencesCount}
        setAvailableMicrofencesCount={setAvailableMicrofencesCount}
        availableLayers={availableLayers}
        setAvailableLayers={setAvailableLayers}
        customIsEqual={customIsEqual}
        setCustomIsEqual={setCustomIsEqual}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        emailData={emailData}
        setEmailData={setEmailData}
        eventIds={eventIds}
        setEventIds={setEventIds}
        eventName={eventName}
        setEventName={setEventName}
        featuresLoading={featuresLoading}
        setFeaturesLoading={setFeaturesLoading}
        selectedTriggerOption={selectedTriggerOption}
        setSelectedTriggerOption={setSelectedTriggerOption}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectionIterator={selectionIterator}
        setSelectionIterator={setSelectionIterator}
        selectedLocationListenerType={selectedLocationListenerType}
        setSelectedLocationListenerType={setSelectedLocationListenerType}
        selectedGeofenceType={selectedGeofenceType}
        setSelectedGeofenceType={setSelectedGeofenceType}
        selectedZone={selectedZone}
        setSelectedZone={setSelectedZone}
        triggerValue={triggerValue}
        setTriggerValue={setTriggerValue}
        selectedNotifierType={selectedNotifierType}
        setSelectedNotifierType={setSelectedNotifierType}
        refreshEBTs={refreshEBTs}
        searchString={searchString}
        setSearchString={setSearchString}
        setRefreshEBTs={setRefreshEBTs}
        selectedTriggerType={selectedTriggerType}
        setSelectedTriggerType={setSelectedTriggerType}
        webhookData={webhookData}
        setWebhookData={setWebhookData}
        featurePageRef={featurePageRef}
        clearForm={clearForm}
        paginateGeofences={paginateGeofences}
        debouncedOnSearchFence={debouncedOnSearchFence}
      />
    </>
  );
};
export default Events;
