import { GridRowData } from '@material-ui/data-grid';
import { Search } from '@mui/icons-material';
import { FormControl, Grid } from '@mui/material';
import { Header } from './Sidebar';
import InputContainer from '../Components/Global/InputContainer';

const QuickSearch = (props: {
  showQuickSearch: boolean;
  searchString: string | undefined;
  onChange: (searchString: string) => void;
}) => {
  return (
    <>
      {props.showQuickSearch && (
        <Grid
          container
          direction="column"
          style={{
            display: 'grid',
            gridTemplateColumns: '10% 90%',
            marginTop: '0px',
          }}
        >
          <div
            style={{
              marginTop: '26px',
            }}
          >
            <Header icon={<Search />}>{''}</Header>
          </div>

          <FormControl fullWidth>
            <InputContainer
              id="searchString"
              label=""
              key={'searchString'}
              name={'searchString'}
              value={props.searchString ?? ''}
              onChange={(e: { target: { value: string } }) => props.onChange(e.target.value)}
              placeholder="Search"
            />
          </FormControl>
        </Grid>
      )}
    </>
  );
};
export default QuickSearch;
