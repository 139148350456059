import { GridRowData } from '@material-ui/data-grid';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import QuickSearch from '../../../../Common/QuickSearch';
import { BACKGROUND } from '../../../../Style/GeoMobyBaseTheme';
import { microfenceList } from '../../../Microfence/MicrofenceList';
import { EBTProps } from '../../Props';

const SelectAMicrofence = (
  props: EBTProps & {
    showSelectAMicrofence: boolean;
  },
) => {
  const getMicrofenceFilter = (searchName: string): GridRowData[] => {
    const microfenceList = searchName
      ? props.availableMicrofences.filter(microfence =>
          microfence.name?.toLowerCase().includes(searchName?.toLowerCase()),
        )
      : props.availableMicrofences;
    props.setAvailableMicrofencesCount(microfenceList.length);
    return microfenceList;
  };

  return (
    <>
      {props.showSelectAMicrofence && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              background: BACKGROUND,
            }}
          >
            {props.selectedFeature
              ? `Proximity: ${props.selectedFeature.name}`
              : `Select Proximity`}
          </AccordionSummary>
          <AccordionDetails
            style={{
              background: BACKGROUND,
            }}
          >
            <Box style={{ height: '356px' }}>
              {/* Fence Search */}
              {QuickSearch({
                showQuickSearch: true,
                searchString: props.searchString,
                onChange: (searchName: string) => {
                  props.setSelectionIterator(0);
                  props.setSearchString(searchName);
                  getMicrofenceFilter(searchName);
                },
              })}

              {/* Microfence List */}
              <Grid
                container
                style={{
                  display: 'grid',
                  gridTemplateColumns: '50% 50%',
                  marginTop: '15px',
                }}
              >
                <InputLabel id="select-microfence">
                  {props.availableMicrofencesCount > 0
                    ? 'Select a Microfence'
                    : 'No microfences available'}
                </InputLabel>
                <Typography style={{ justifySelf: 'self-end' }}>{`${
                  props.availableMicrofencesCount
                } ${
                  Number(props.availableMicrofencesCount) === 1 ? 'result' : 'results'
                }`}</Typography>
              </Grid>
              {props.availableMicrofencesCount > 0 && (
                <Grid
                  container
                  direction={'row'}
                  style={{
                    marginTop: '10px',
                    height: 'fit-content',
                    maxHeight: '239px',
                    overflowY: 'hidden',
                  }}
                >
                  {microfenceList({
                    availableMicrofences: props.searchString
                      ? getMicrofenceFilter(props.searchString).slice(
                          props.selectionIterator,
                          props.selectionIterator + 5,
                        )
                      : props.availableMicrofences.slice(
                          props.selectionIterator,
                          props.selectionIterator + 5,
                        ),
                    selectedMicroFence: props.selectedFeature,
                    showList: props.availableMicrofencesCount > 0,
                    onClick: (microfence: GridRowData) => {
                      props.setSelectedFeature(prevMicrofence =>
                        prevMicrofence === microfence ? undefined : microfence,
                      );
                    },
                  })}
                </Grid>
              )}
            </Box>

            {/* Next and Previous */}
            <Grid
              container
              style={{
                display: 'grid',
                gridTemplateColumns: '60px 60px',
                gap: '10px',
                justifyContent: 'right',
                paddingRight: '21px',
                paddingTop: '12px',
              }}
            >
              <Button
                variant="outlined"
                disabled={props.availableMicrofencesCount === 0}
                onClick={e => {
                  props.setSelectionIterator(selectionIterator =>
                    selectionIterator - 5 < 0 ? 0 : selectionIterator - 5,
                  );
                }}
              >
                <ChevronLeft />
              </Button>
              <Button
                variant="outlined"
                disabled={props.availableMicrofencesCount === 0}
                onClick={e => {
                  props.setSelectionIterator(selectionIterator =>
                    selectionIterator + 5 >= props.availableMicrofencesCount
                      ? selectionIterator
                      : selectionIterator + 5,
                  );
                }}
              >
                <ChevronRight />
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
export default SelectAMicrofence;
